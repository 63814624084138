import React from 'react'
import { Combobox } from '@headlessui/react'
import { DropdownItem } from 'types'

interface OptionRenderPropArg {
    open: boolean
}

interface commonComboboxProps {
    disabled?: boolean
    onChange: (v: DropdownItem | null) => void
    children:
        | JSX.Element
        | JSX.Element[]
        | ((props: OptionRenderPropArg) => JSX.Element)
}

type ComboboxProps = (
    | {
          value?: DropdownItem
      }
    | {
          value: DropdownItem[]
      }
) &
    commonComboboxProps

const DropdownCombobox = React.forwardRef<HTMLElement, ComboboxProps>(
    (props, ref) => {
        const { value, onChange, disabled, children } = props

        if (typeof children === 'function') {
            return (
                <Combobox
                    value={value}
                    onChange={onChange as any}
                    disabled={disabled}
                >
                    {({ open }) => (
                        <>
                            <div ref={ref as any} className="relative">
                                {children({ open })}
                            </div>
                        </>
                    )}
                </Combobox>
            )
        }

        return (
            <Combobox
                value={value}
                onChange={onChange as any}
                disabled={disabled}
            >
                <div ref={ref as any} className="relative">
                    {children}
                </div>
            </Combobox>
        )
    }
)

export default DropdownCombobox
