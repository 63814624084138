import { useQuery, useMutation } from 'react-query'
import qs from 'qs'
import type { UseMutationOptions } from 'react-query'

import client from '../client'
import { QUERY_CACHE_PATTERNS_KEY } from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type {
    ResponseList,
    ResponseError,
    DeleteVariables,
    PatternListResponse,
    PatternResponse,
    ResponseData,
    CreatePatternVariables,
    UpdatePatternVariables,
} from '../types'

export const getPattern = <T>(filters?: {}): Promise<T> =>
    client.get('/patterns' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const usePatternQuery = <T = ResponseList<PatternListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PATTERNS_KEY, 'index', filters],
        () => getPattern<T>({ ...filters }),
        options
    )

const createPattern = ({
    data,
}: CreatePatternVariables): Promise<ResponseData<PatternResponse>> =>
    client.post('/patterns', data)

export const useCreatePattern = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PatternResponse>,
            ResponseError,
            CreatePatternVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createPattern(data), options)

const updatePattern = ({
    id,
    data,
}: UpdatePatternVariables): Promise<ResponseData<PatternResponse>> =>
    client.patch(`/patterns/${id}`, data)

export const useUpdatePattern = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PatternResponse>,
            ResponseError,
            UpdatePatternVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updatePattern(data), options)

const deletePattern = ({ id }: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/patterns/${id}`)

export const useDeletePattern = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deletePattern(data), options)
