import React, { useMemo, useReducer, useEffect, useCallback } from 'react'

import { applyAuthInterceptors } from '../api/client'
import AuthContext from './AuthContext'

import type { AuthContext as TAuthContext } from './AuthContext'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../constants/index'

export type AuthAction =
    | { type: 'RESTORE_AUTH_STATE'; isSignedIn: boolean }
    | { type: 'SIGN_IN' }
    | { type: 'SIGN_OUT' }

export interface AuthState {
    isSignedIn: boolean
    isLoading: boolean
}

const setToken = (token: string) =>
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token)
const clearToken = () => localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
const getToken = () => localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

const INITIAL_AUTH_STATE = {
    isSignedIn: false,
    isLoading: true,
}

const authReducer = (prevState: AuthState, action: AuthAction) => {
    switch (action.type) {
        case 'RESTORE_AUTH_STATE':
            return {
                ...prevState,
                isSignedIn: action.isSignedIn,
                isLoading: false,
            }
        case 'SIGN_IN':
            return {
                ...prevState,
                isSignedIn: true,
            }
        case 'SIGN_OUT':
            return {
                ...prevState,
                isSignedIn: false,
            }
    }
}

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(authReducer, INITIAL_AUTH_STATE)

    const setAuthToken = useCallback(
        async (token: string, expires_at: string) => {
            setToken(token)
            dispatch({ type: 'SIGN_IN' })
        },
        []
    )

    const clearAuthToken = useCallback(() => {
        clearToken()
        dispatch({ type: 'SIGN_OUT' })
    }, [dispatch])

    useEffect(() => {
        const restoreAuthState = () => {
            let token

            try {
                token = getToken()
            } catch (e) {}

            applyAuthInterceptors({
                clearToken: clearAuthToken,
                getToken,
            })

            dispatch({ type: 'RESTORE_AUTH_STATE', isSignedIn: !!token })
        }

        restoreAuthState()
    }, [setAuthToken, clearAuthToken])

    const contextValue: TAuthContext = useMemo(
        () => ({
            setToken: setAuthToken,
            clearToken: clearAuthToken,
            getToken,
            ...state,
        }),
        [state, setAuthToken, clearAuthToken]
    )

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
