import { useQuery, useMutation } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_PLOTTERS_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    PlotterResponse,
    PlotterListResponse,
    DeleteVariables,
    UpdatePlotterVariables,
    CreatePlotterVariables,
} from '../types'

export const getPlotter = <T>(filters?: {}): Promise<T> =>
    client.get('/cutters' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const usePlotterQuery = <T = ResponseList<PlotterListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PLOTTERS_KEY, 'index', filters],
        () => getPlotter<T>(filters),
        options
    )

export const getPlotterById = (
    id: string
): Promise<ResponseData<PlotterResponse>> => client.get(`/cutters/${id}`)

export const usePlotterByIdQuery = <TData = ResponseData<PlotterResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<PlotterResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery(
        [QUERY_CACHE_PLOTTERS_KEY, id],
        () => getPlotterById(id),
        queryOptions
    )

const createPlotter = ({
    data,
}: CreatePlotterVariables): Promise<ResponseData<PlotterResponse>> =>
    client.post('/cutters', data)

export const useCreatePlotter = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PlotterResponse>,
            ResponseError,
            CreatePlotterVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createPlotter(data), options)

const updatePlotter = ({
    id,
    data,
}: UpdatePlotterVariables): Promise<ResponseData<PlotterResponse>> =>
    client.patch(`/cutters/${id}`, data)

export const useUpdatePlotter = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<PlotterResponse>,
            ResponseError,
            UpdatePlotterVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updatePlotter(data), options)

const deletePlotter = ({ id }: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/cutters/${id}`)

export const useDeletePlotter = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deletePlotter(data), options)
