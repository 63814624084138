import React from 'react'
import classNames from 'classnames'
import { ComboboxButton } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { ChevronUpIcon } from '@heroicons/react/outline'

interface ComboboxProps {
    disabled?: boolean
    hasError?: boolean
    open: boolean
    onClick?: () => void
}

const DropdownComboboxButton = (props: ComboboxProps) => {
    const { disabled, hasError, open, onClick } = props
    return (
        <ComboboxButton
            onClick={onClick}
            className={classNames(
                'absolute inset-y-0 right-0 flex items-center w-full rounded-r-md px-2 focus:outline-none',
                {
                    'focus:ring-red-600 focus:border-red-600':
                        !disabled && !hasError,
                    'border-red-300 text-red-700 ring-1 ring-offset-0 ring-red-300':
                        !disabled && hasError,
                    'pointer-events-none': disabled,
                    'cursor-pointer': !disabled,
                    'ring-1 ring-offset-0 ring-red-600 border-red-600':
                        !disabled && hasError && open,
                }
            )}
            disabled={disabled}
        >
            <span className="text-gray-50 truncate font-semibold relative w-full py-2 pl-3 pr-10 text-left cursor-pointer order border-gray-800 shadow-sm max-h-60 rounded-md text-base overflow-auto focus:outline-none sm:text-sm">
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    {!open && (
                        <ChevronDownIcon
                            className={classNames('h-5 w-5', {
                                'text-gray-100': !disabled && !hasError,
                                'text-red-500': !disabled && hasError,
                                'text-gray-300': disabled,
                            })}
                            aria-hidden="true"
                        />
                    )}
                    {open && (
                        <ChevronUpIcon
                            className={classNames('h-5 w-5 text-gray-400', {
                                'text-gray-100': !disabled && !hasError,
                                'text-red-500': !disabled && hasError,
                                'text-gray-300': disabled,
                            })}
                            aria-hidden="true"
                        />
                    )}
                </span>
            </span>
        </ComboboxButton>
    )
}

export default DropdownComboboxButton
