import React from 'react'
import classNames from 'classnames'
import { RadioGroupLabel as Label } from '@headlessui/react'

import type { OptionRenderPropArg } from './RadioGroupOption'

const RadioGroupLabel: React.FC<OptionRenderPropArg> = ({
    checked,
    active,
    disabled,
    children,
}) => {
    return (
        <Label
            className={classNames(
                'flex-1 rounded-md relative p-3 flex text-gray-200 focus:outline-none',
                {
                    'pointer-events-none cursor-default': disabled,
                    'cursor-pointer': !disabled,
                }
            )}
        >
            <span
                className={classNames(
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                    {
                        'bg-red-600 border-transparent': checked,
                        'bg-gray-800 border-gray-700': !checked,
                        'ring-2 ring-offset-2 ring-offset-[#141313] ring-red-500':
                            active,
                    }
                )}
                aria-hidden="true"
            >
                <span
                    className={classNames('rounded-full w-1.5 h-1.5', {
                        'bg-white': checked,
                    })}
                />
            </span>
            <div className="ml-3 flex flex-col">
                <span
                    className={classNames(
                        'block text-sm font-medium text-gray-200'
                    )}
                >
                    {children}
                </span>
            </div>
        </Label>
    )
}

export default RadioGroupLabel
