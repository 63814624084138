import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'

import { getCxFromStyles, userMapFn } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'

import type {
    FormSubmitFn,
    IModal,
    AssignUserForm,
    DropdownItem,
} from '../types'

import styles from './Modal.module.scss'
import { useUserRoles } from '../hooks'
import { useTranslation } from 'react-i18next'
import { getUsers } from 'api'
import InfiniteDropdownWithSearchField from './forms/InfiniteDropdownWithSearchField'

export type SelectUserModalProps = {
    modal: IModal
    onSubmit: FormSubmitFn<AssignUserForm>
    initialUser?: DropdownItem
    title: string
    description: string
}

export default function SelectUserModal({
    modal,
    onSubmit,
    initialUser,
    title,
    description,
}: SelectUserModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()
    const userRoles = useUserRoles()

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-white text-center"
                >
                    {title}
                </DialogTitle>
                <Formik<AssignUserForm>
                    onSubmit={onSubmit}
                    initialValues={{
                        assignedUser: initialUser,
                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form className="px-2 pb-2">
                            <div className="py-6 text-gray-400 text-sm leading-5 font-normal">
                                {description}
                            </div>
                            <div>
                                <Label>{t('attach.user.label')}</Label>
                                <InfiniteDropdownWithSearchField
                                    name="assignedUser"
                                    queryFn={getUsers}
                                    mapFn={userMapFn}
                                    queryFilters={{
                                        roles: [userRoles.user.id],
                                    }}
                                />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    disabled={
                                        isSubmitting || !values.assignedUser
                                    }
                                    loading={isSubmitting}
                                >
                                    {t('form.add')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
