import { useCurrentUser } from './useCurrentUser'
import { useUserRoles } from './useUserRoles'

interface iPolicy {
    isAdmin: () => boolean
    isSupervisor: () => boolean
    isUser: () => boolean
}

export const usePolicy = (): iPolicy => {
    const user = useCurrentUser()
    const roles = useUserRoles()

    if (!user.isSuccess) {
        return {
            isAdmin: () => false,
            isSupervisor: () => false,
            isUser: () => true,
        }
    }

    return {
        isAdmin: () => String(user.data?.data.role?.id) === roles.admin.id,
        isSupervisor: () =>
            String(user.data?.data.role?.id) === roles.supervisor.id,
        isUser: () => String(user.data?.data.role?.id) === roles.user.id,
    }
}
