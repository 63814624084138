import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label, Dropdown } from './ui'

import {
    DropdownItem,
    PatternListFilterAction,
    PatternListFilterState,
} from '../types'
import { useDeviceTypesDropdown, useFoilSizesDropdown } from '../hooks'
import { getBrand, getDevices } from 'api'
import InfiniteDropdownWithSearch from './ui/InfiniteDropdownWithSearchFilter'
import { brandMapFn, deviceMapFn } from 'helpers'

const PatternListFilters: React.FC<{
    filters: PatternListFilterState
    filterAction: React.Dispatch<PatternListFilterAction>
}> = ({ filters, filterAction }) => {
    const { t } = useTranslation()

    const deviceTypes = useDeviceTypesDropdown()
    const foilSizes = useFoilSizesDropdown()
    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }

    return (
        <div className="mt-3 px-4 py-3 mb-4 bg-gray-800 rounded-lg">
            <div className="grid grid-cols-4 gap-4">
                <div>
                    <Label>{t('filters.brand')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.brand}
                            queryFn={getBrand}
                            name="brands"
                            mapFn={brandMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterBrand',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.brand?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.device')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.device}
                            queryFn={getDevices}
                            queryFilters={{ brand_id: filters.brand?.id }}
                            name="devices"
                            mapFn={deviceMapFn}
                            disabled={!filters.brand}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterDevice',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.device?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.type')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(deviceTypes)}
                            value={
                                deviceTypes.find(
                                    (item) => item.id === filters.type?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterType',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.size')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(foilSizes)}
                            value={
                                foilSizes.find(
                                    (item) => item.id === filters.size?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterSize',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatternListFilters
