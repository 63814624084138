import React from 'react'
import { useField } from 'formik'
import { DropdownItem } from 'types'
import DropdownWithSearch from 'components/ui/Dropdowns/DropdownWithSearch'
import {
    useDropdown,
    useDropdownLocalSearch,
} from 'components/ui/Dropdowns/hooks'

interface DropdownWithSearchProps {
    name: string
    disabled?: boolean
    items: DropdownItem[]
}

const DropdownWithSearchField = (props: DropdownWithSearchProps) => {
    const { name } = props
    const [field, fieldMeta, helpers] = useField(name)
    const { filteredItems, setSearchPhrase, onBlur } = useDropdownLocalSearch(
        props.items
    )
    const { onChange } = useDropdown((v) => helpers.setValue(v || undefined))

    return (
        <DropdownWithSearch
            setSearchPhrase={setSearchPhrase}
            disabled={props.disabled}
            error={!!fieldMeta.error}
            items={filteredItems}
            value={field.value}
            onBlur={onBlur}
            displayValue={field.value?.name || ''}
            onChange={onChange}
        />
    )
}

export default DropdownWithSearchField
