import React from 'react'
import { CSSTransition } from 'react-transition-group'
import {
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
    ExclamationIcon,
} from '@heroicons/react/solid'

import { getCxFromStyles } from '../helpers'

import styles from './FlashMessage.module.scss'

export type FlashMessageType = 'success' | 'warning' | 'danger'

const TRANSITION_STYLES = {
    enter: styles.enter,
    enterActive: styles.enterActive,
    exit: styles.exit,
    exitActive: styles.exitActive,
    appear: styles.appear,
    appearActive: styles.appearActive,
}

const FlashMessage: React.FC<{
    dismiss?: () => void
    type?: FlashMessageType
    show?: boolean
    children?: React.ReactNode
}> = ({ dismiss, type = 'success', children, show }) => {
    const cx = getCxFromStyles(styles)

    return (
        <CSSTransition
            in={show}
            timeout={300}
            classNames={TRANSITION_STYLES}
            unmountOnExit
            appear
        >
            <div
                className={cx(
                    'transition duration-300 flex md:inline-flex flex-row items-center p-4 text-sm leading-5 font-medium rounded-md ',
                    styles[type]
                )}
            >
                <span className={cx('mr-3', styles.icon)}>
                    {type === 'success' && (
                        <CheckCircleIcon className="h-5 w-5" />
                    )}
                    {type === 'danger' && <XCircleIcon className="h-5 w-5" />}
                    {type === 'warning' && (
                        <ExclamationIcon className="h-5 w-5" />
                    )}
                </span>
                <div className="mr-7">{children}</div>
                {dismiss && (
                    <div
                        className="ml-auto flex-shrink-0 flex cursor-pointer"
                        role="button"
                        onClick={dismiss}
                    >
                        <span className={styles.closeIcon}>
                            <XIcon className="h-5 w-5" />
                        </span>
                    </div>
                )}
            </div>
        </CSSTransition>
    )
}

export default FlashMessage