import { useMutation } from 'react-query'

import client from '../client'

import type { UseMutationResult } from 'react-query'
import type {
    ResponseError,
    LoginRequest,
    LoginResponse,
    ResponseData,
} from '../types'
import { LoginForm } from '../../types'

const login = (data: LoginRequest): Promise<ResponseData<LoginResponse>> =>
    client.post('/login', data)

export const useLogin = (): UseMutationResult<
    ResponseData<LoginResponse>,
    ResponseError,
    LoginForm
> => useMutation((data) => login({ ...data, device_name: 'web' }))
