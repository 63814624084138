import React, { useState, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_BRANDS_KEY,
} from '../constants/index'
import { useCreateBrand, useBrandQuery, useUpdateBrand } from '../api'
import { listReducer } from '../helpers'
import { Button, FilterButton } from '../components/ui'
import PageLayout from '../components/PageLayout'
import BrandList from '../components/BrandList'
// import BrandListFilters from '../components/BrandListFilters'

import type {
    BrandListFilterState,
    BrandListFilterAction,
    ListFilterAction,
    BrandForm,
    FormSubmitFn,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useDeleteBrandForm } from '../hooks/forms/useDeleteBrandForm'
import { useQueryClient } from 'react-query'
import BrandModal from '../components/BrandModal'
import { useFlashMessage, useModal } from '../hooks'
import { BrandResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'

const filterReducer = (
    state: BrandListFilterState,
    action: BrandListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            page: 1,
        }
    }

    return listReducer(
        state,
        action as ListFilterAction
    ) as BrandListFilterState
}

export default function BrandListView() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const flashMessage = useFlashMessage()
    const createBrandModal = useModal()
    const updateBrandModal = useModal<{
        id: number
        payload: BrandResponse
    }>()

    const deleteBrandForm = useDeleteBrandForm(() => {
        queryClient.invalidateQueries([QUERY_CACHE_BRANDS_KEY, 'index'])
    })

    const createBrand = useCreateBrand()
    const updateBrand = useUpdateBrand()
    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const filterCount = 0
    // const filterCount = useMemo(
    //     () =>
    //         (filters.country ? 1 : 0) +
    //         (filters.role ? 1 : 0) +
    //         (filters.status ? 1 : 0),
    //     [filters]
    // )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            status: undefined,
            // roles: filters.role ? [filters.role.id] : undefined,
            // countries: filters.country ? [filters.country.id] : undefined,
            // active: filters.status
            //     ? filters.status.id === 'active'
            //         ? 1
            //         : 0
            //     : undefined,
            search_global: filters.search_global || undefined,
        }
    }, [filters])

    const handleSubmit: FormSubmitFn<BrandForm> = (values, formikHelpers) => {
        createBrand.mutate(
            {
                data: {
                    name: values.name,
                    logo: values.logo || undefined,
                    order: values.order,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.brand.saved'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_BRANDS_KEY,
                        'index',
                    ])
                    createBrandModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const handleSubmitEdit: FormSubmitFn<BrandForm> = (
        values,
        formikHelpers
    ) => {
        updateBrand.mutate(
            {
                id: updateBrandModal.getState()!.id,
                data: {
                    name: values.name,
                    logo: values.logo || undefined,
                    order: values.order,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.brand.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_BRANDS_KEY,
                        'index',
                    ])
                    updateBrandModal.closeModal()
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    const brandsQuery = useBrandQuery(apiFilters)
    const policy = usePolicy()

    if (!policy.isAdmin()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('list.brand.title')}
            searchBarValue={filters.search_global}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button onClick={createBrandModal.openModal}>
                            {t('action.brand.create')}
                        </Button>
                    </span>
                    {/* <span className="ml-6">
                        <FilterButton
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span> */}
                </>
            }
        >
            <>
                {/* {filtersExpanded && (
                    <BrandListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )} */}
                <BrandList
                    brandsQuery={brandsQuery}
                    filters={filters}
                    filterCount={filterCount + filters.search_global.length}
                    handleEdit={(id, payload) => {
                        updateBrandModal.setState({ id, payload })
                        updateBrandModal.openModal()
                    }}
                    handleRemove={(id) => {
                        deleteBrandForm.modal.setState(id)
                        deleteBrandForm.modal.openModal()
                    }}
                    filterAction={dispatchFilterAction}
                />
                <BrandModal modal={createBrandModal} onSubmit={handleSubmit} />
                <BrandModal
                    modal={updateBrandModal}
                    brandData={updateBrandModal.getState()?.payload}
                    onSubmit={handleSubmitEdit}
                />
                <ConfirmModal
                    modal={deleteBrandForm.modal}
                    title={t('delete.brand.title')}
                    description={t('delete.brand.description')}
                    actionLabel={t('action.delete')}
                    onSubmit={deleteBrandForm.form.submitForm}
                    isSubmitting={deleteBrandForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
