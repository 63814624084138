import React from 'react'
import classNames from 'classnames'

export default function Label({
    children,
    htmlFor,
    className,
}: React.LabelHTMLAttributes<HTMLLabelElement>) {
    return (
        <label
            htmlFor={htmlFor}
            className={classNames(
                'inline-block text-sm leading-5 font-medium text-gray-100 pb-1',
                className
            )}
        >
            {children}
        </label>
    )
}
