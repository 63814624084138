import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label, Dropdown } from './ui'
import InfiniteDropdownWithSearch from './ui/InfiniteDropdownWithSearchFilter'

import {
    DropdownItem,
    DeviceListFilterAction,
    DeviceListFilterState,
} from '../types'
import { useDeviceTypesDropdown, useDeviceStatuses } from '../hooks'
import { getBrand } from 'api'
import { brandMapFn } from 'helpers'

const DeviceListFilters: React.FC<{
    filters: DeviceListFilterState
    filterAction: React.Dispatch<DeviceListFilterAction>
}> = ({ filters, filterAction }) => {
    const { t } = useTranslation()

    const deviceTypes = useDeviceTypesDropdown()
    const deviceStatuses = useDeviceStatuses()

    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }

    return (
        <div className="mt-3 px-4 py-3 mb-4 bg-gray-800 rounded-lg">
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <Label>{t('filters.brand')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.brand}
                            queryFn={getBrand}
                            name="brands"
                            mapFn={brandMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterBrand',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.brand?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.type')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(deviceTypes)}
                            value={
                                deviceTypes.find(
                                    (item) => item.id === filters.type?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterType',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.patterns')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(deviceStatuses)}
                            value={
                                deviceStatuses.find(
                                    (item) =>
                                        item.id === filters.hasPatterns?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterPatterns',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeviceListFilters
