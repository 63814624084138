import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function useChangeLang(lngStr?: string) {
    const { i18n } = useTranslation()

    useEffect(() => {
        lngStr && i18n.changeLanguage(lngStr)
    }, [lngStr, i18n])
}
