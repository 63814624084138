import React from 'react'
import { DropdownItem } from 'types'
import { ComboboxDropdownItem, ComboboxEmptyDropdownItem } from 'components/ui'
import DropdownCombobox from './DropdownBase/DropdownCombobox'
import DropdownComboboxOptions from './DropdownBase/DropdownComboboxOptions'
import DropdownComboboxInput from './DropdownBase/DropdownComboboxInput'
import DropdownComboboxButton from './DropdownBase/DropdownComboboxSearchButton'

interface DropdownWithSearchProps {
    value: DropdownItem
    items: DropdownItem[]
    displayValue: string
    disabled?: boolean
    error?: boolean
    setSearchPhrase: (value: string) => void
    onChange: (v: DropdownItem | null) => void
    onBlur: () => void
}

const DropdownWithSearch = (props: DropdownWithSearchProps) => {
    return (
        <DropdownCombobox
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChange}
        >
            {({ open }) => (
                <>
                    <DropdownComboboxInput
                        displayValue={props.displayValue}
                        onChange={props.setSearchPhrase}
                        onBlur={props.onBlur}
                        disabled={props.disabled}
                        hasError={!!props.error}
                        open={open}
                    />
                    <DropdownComboboxButton
                        disabled={props.disabled}
                        hasError={!!props.error}
                        open={open}
                    />
                    <DropdownComboboxOptions open={open}>
                        {!props.items.length && <ComboboxEmptyDropdownItem />}
                        {props.items.map((item) => {
                            const selected = props.value?.id === item.id

                            return (
                                <ComboboxDropdownItem
                                    key={item.id}
                                    item={item}
                                    selected={selected}
                                />
                            )
                        })}
                    </DropdownComboboxOptions>
                </>
            )}
        </DropdownCombobox>
    )
}

export default DropdownWithSearch
