import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import * as Sentry from '@sentry/react'

import Routes from './Routes'
import FlashMessageProvider from './contexts/FlashMessageProvider'
import AuthProvider from './contexts/AuthProvider'
import reportWebVitals from './reportWebVitals'
import i18n from './i18n'

import './index.css'

const { REACT_APP_SENTRY_DSN_URL, REACT_APP_SENTRY_ENVIRONMENT } = process.env

if (REACT_APP_SENTRY_DSN_URL && REACT_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN_URL,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        environment: REACT_APP_SENTRY_ENVIRONMENT,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 120_000,
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n} defaultNS={'translation'}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <FlashMessageProvider>
                        <AuthProvider>
                            <Routes />
                        </AuthProvider>
                    </FlashMessageProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </I18nextProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
