import React from 'react'
import { Menu, MenuButton } from '@headlessui/react'

import Items from './Menu3dotsItems'
import Item from './Menu3dotsItem'

type MenuRenderPropArg = {
    open: boolean
}

type Menu3dotsProps = {
    children: JSX.Element | ((bag: MenuRenderPropArg) => JSX.Element)
}

function Menu3dots({ children }: Menu3dotsProps) {
    return (
        <Menu as="div" className="relative">
            {(props) =>
                typeof children === 'function' ? children(props) : children
            }
        </Menu>
    )
}

Menu3dots.Items = Items
Menu3dots.Item = Item
Menu3dots.Button = MenuButton

export default Menu3dots
