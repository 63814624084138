import { useQuery, useMutation } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_BRANDS_KEY } from '../../constants/index'

import type {
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    BrandResponse,
    BrandListResponse,
    DeleteVariables,
    UpdateBrandVariables,
    CreateBrandVariables,
} from '../types'

export const getBrand = <T>(filters?: {}): Promise<T> =>
    client.get('/brands' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useBrandQuery = <T = ResponseList<BrandListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_BRANDS_KEY, 'index', filters],
        () => getBrand<T>(filters),
        options
    )

export const getBrandById = (
    id: string
): Promise<ResponseData<BrandResponse>> => client.get(`/brands/${id}`)

export const useBrandByIdQuery = <TData = ResponseData<BrandResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<BrandResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery([QUERY_CACHE_BRANDS_KEY, id], () => getBrandById(id), queryOptions)

const createBrand = ({
    data,
}: CreateBrandVariables): Promise<ResponseData<BrandResponse>> =>
    client.post('/brands', data)

export const useCreateBrand = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<BrandResponse>,
            ResponseError,
            CreateBrandVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createBrand(data), options)

const updateBrand = ({
    id,
    data,
}: UpdateBrandVariables): Promise<ResponseData<BrandResponse>> =>
    client.patch(`/brands/${id}`, data)

export const useUpdateBrand = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<BrandResponse>,
            ResponseError,
            UpdateBrandVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateBrand(data), options)

const deleteBrand = ({ id }: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/brands/${id}`)

export const useDeleteBrand = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteBrand(data), options)
