import React from 'react'
import { Outlet } from 'react-router-dom'

import { ReactComponent as Logo } from '../assets/logo.svg'
import { ReactComponent as Pattern } from '../assets/pattern.svg'

export default function LoginLayout() {
    return (
        <div className="relative ">
            <div className="absolute right-0 top-12">
                <Pattern />
            </div>
            <div className="inline-flex flex-col pb-32 pt-64 items-center gap-12 mx-auto w-full">
                <div className="">
                    <h2 className="">
                        <Logo />
                    </h2>
                </div>
                <div className="">
                    <div className="">
                        <Outlet />
                    </div>
                </div>
            </div>
            <div className="absolute top-[460px]">
                <Pattern />
            </div>
        </div>
    )
}
