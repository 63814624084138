import React from 'react'
import pl from 'date-fns/locale/pl'
import enGB from 'date-fns/locale/en-GB'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import { DateFormatter, DateRange } from 'react-day-picker'
import { Options as FocusTrapOptions } from 'focus-trap'
import {
    Locale,
    isMonday,
    isTuesday,
    isWednesday,
    isThursday,
    isFriday,
    isSaturday,
    isValid,
} from 'date-fns'
import type {
    ClassNames,
    CustomComponents,
    Formatters,
    DayModifiers,
    ModifiersClassNames,
} from 'react-day-picker'

import styles from '../DatePicker.module.scss'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser'

const DATE_REGEX = new RegExp('^[0-3][0-9].[0-1][0-9].([1-2]\\d{3})$')

interface DatePickerConfig {
    locale: Locale
    modifiers: DayModifiers
    modifiersClassNames: ModifiersClassNames
    classNames: ClassNames
    components: CustomComponents
    formatters: Partial<Formatters>
}

const useFormatWeekdayName: () => DateFormatter = () => {
    const { t } = useTranslation()

    return (day) => {
        if (isMonday(day)) return t('day.monday')
        if (isTuesday(day)) return t('day.tuesday')
        if (isWednesday(day)) return t('day.wednesday')
        if (isThursday(day)) return t('day.thursday')
        if (isFriday(day)) return t('day.friday')
        if (isSaturday(day)) return t('day.saturday')

        return t('day.sunday')
    }
}

const useGetLocale: () => Locale = () => {
    const user = useCurrentUser()

    if (!user.data?.data.lang) {
        return enGB
    }

    switch (user.data.data.lang) {
        case 'pl':
            return pl
        case 'en':
            return enGB
        default:
            return enGB
    }
}

export const useGetDatePickerConfig = (
    blockedDays: Array<DateRange | Date>,
    modifiers?: DayModifiers
): DatePickerConfig => {
    const formatWeekdayName = useFormatWeekdayName()
    const locale = useGetLocale()

    return {
        locale: locale,
        modifiers: { ...modifiers, unavailableDays: blockedDays },
        modifiersClassNames: {
            selected: styles.selected,
            today: styles.today,
            unavailableDays: styles.blockedDay,
            range_start: styles.rangeStart,
            range_end: styles.rangeEnd,
        },
        classNames: {
            root: styles.dayPickerRoot,
            caption_label: styles.captionLabel,
            head_cell: styles.headCell,
            day: styles.day,
            cell: styles.cell,
            nav_button: styles.navButton,
            caption_start: styles.captionStart,
            caption_end: styles.captionEnd,
            caption: styles.caption,
        },
        components: {
            IconRight: () => <ArrowRightIcon className={styles.navIcon} />,
            IconLeft: () => <ArrowLeftIcon className={styles.navIcon} />,
        },
        formatters: { formatWeekdayName },
    }
}

export const getFocusTrapOptions = (
    onDeactivate: () => void
): FocusTrapOptions => ({
    initialFocus: false,
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    onDeactivate,
})

export const getDateFromUiDateString = (ddmmyyyFormat: string): Date | null => {
    if (ddmmyyyFormat.match(DATE_REGEX)) {
        const dateArr = ddmmyyyFormat.split('.')
        const date = new Date(`${dateArr[1]}.${dateArr[0]}.${dateArr[2]}`)

        if (isValid(date)) {
            return date
        }
    }

    return null
}
