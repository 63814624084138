import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from '@heroicons/react/solid'
import debounce from 'lodash.debounce'

const SearchBar: React.FC<{
    value: string
    action: (value: string) => void
}> = ({ value, action }) => {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState('')

    const debouncedAction = useCallback(debounce(action, 300), [action]) // eslint-disable-line
    const handleChange = (value: string) => {
        setInputValue(value)
        debouncedAction(value)
    }

    return (
        <div className="border-b-2 border-b-gray-200 py-2 h-16">
            <div className="relative h-full flex">
                <div className="absolute inset-y-0 left-0 pr-3 flex items-center pointer-events-none">
                    <SearchIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                    />
                </div>
                <input
                    type="text"
                    name="name"
                    className="block w-full ml-8 appearance-none border-none focus:border-none outline-none bg-[#141313]"
                    autoComplete="off"
                    placeholder={t('action.search')}
                    value={inputValue}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>
        </div>
    )
}

export default SearchBar
