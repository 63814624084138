import type { DropdownItem } from '../types'

export const LANGUAGES: DropdownItem[] = [
    {
        id: 'en',
        name: 'English',
    },
    {
        id: 'pl',
        name: 'Polski',
    },
    {
        id: 'de',
        name: 'Deutsche',
    },
    {
        id: 'cz',
        name: 'český',
    },
    {
        id: 'sk',
        name: 'Slovenský',
    },
]
