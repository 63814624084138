import React, { useEffect, useRef, useState } from 'react'
import { isBefore, isEqual } from 'date-fns'
import {
    DateRange as ReactDayPickerDateRange,
    DayPicker,
} from 'react-day-picker'

import { useEventListener } from 'hooks'
import { getCxFromStyles } from 'helpers'
import {
    useGetDatePickerConfig,
    CommonDatePickerProps,
    RangeInputType,
    RangeDatePickerInput,
    DayButtons,
    useDatePickerPopper,
    DaysCheckboxDropdown,
} from './DatePickerBase'

import 'react-day-picker/dist/style.css'
import styles from './DatePicker.module.scss'

export interface DateRange extends ReactDayPickerDateRange {
    days?: string[]
}

export interface RangeDatePickerProps
    extends Omit<
        CommonDatePickerProps<DateRange>,
        'disabledBefore' | 'disabledAfter'
    > {
    placeholderFrom?: string
    placeholderTo?: string
    disabledBefore?: Date
    disabledAfter?: Date
    size?: 'sm' | 'md'
    sideMenu?: boolean
    onBlur?: () => void
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({
    placeholderFrom = 'Rozpoczęcie',
    placeholderTo = 'Zakończenie',
    blockedDays = [],
    disabledBefore,
    disabledAfter,
    value,
    onChange,
    onBlur,
    size = 'md',
    sideMenu,
    disabled,
    hasError,
}) => {
    const ref = useRef<HTMLDivElement | null>(null)

    const [dateRange, setDateRange] = useState<DateRange>({
        from: value?.from,
        to: value?.to,
        days: value?.days,
    })

    useEffect(() => {
        onChange(dateRange)

        // eslint-disable-next-line
    }, [dateRange])

    const cx = getCxFromStyles(styles)

    const {
        popperRef,
        setPopperElement,
        popperState,
        popper,
        handleClosePopper,
        handleOpenPopper,
    } = useDatePickerPopper()

    const handleChange = (v?: Partial<DateRange>) => {
        setDateRange((prevState) => ({
            ...prevState,
            ...v,
        }))
    }

    const handleOnRangeClick = (openedFrom: RangeInputType) => {
        handleOpenPopper({ openedFrom })
    }

    const getOpenedFrom = () => {
        const of = popperState.options?.openedFrom

        if (of) {
            if (value?.from && !value?.to) return 'to'
            if (!value?.from) return 'from'
        }

        return of
    }

    const openedFrom = getOpenedFrom()

    const datePickerConfig = useGetDatePickerConfig(blockedDays, {
        range_end: value?.to ? [value?.to] : [],
    })

    const isMultipleMonths = size === 'md'

    const onClear = (rangeInputType: RangeInputType) => {
        if (value) {
            handleChange({ [rangeInputType]: undefined })
        }
    }

    const onRangeButtonChange = (value: DateRange) => {
        handleChange(value)
        handleClosePopper()
    }

    // changes from input
    const onInputChange = (date?: DateRange) => {
        if (date) {
            if (date.from && date.to && isBefore(date.to, date.from)) {
                handleChange({ from: date.to, to: date.from })
            } else {
                handleChange(date)
            }
        }
    }

    // changes from selecting date picker
    const onSelect = (selectedDate?: DateRange) => {
        // edge case: if range selected and clicked on FROM
        if (!selectedDate && value?.from && value?.to) {
            if (openedFrom === 'to') {
                handleChange({ to: value?.from })
            }

            handleClosePopper()
            return
        }

        // edge case: TO selected, FROM undefined and select after TO
        if (
            selectedDate &&
            selectedDate.from &&
            !selectedDate?.to &&
            value?.to &&
            !value?.from &&
            isBefore(value.to, selectedDate.from)
        ) {
            handleChange({ from: value.to, to: selectedDate.from })
            handleClosePopper()
            return
        }

        // if date range is already selected and we are modifying only one input
        if (selectedDate && value?.from && value?.to && openedFrom) {
            // edge case: if range selected and clicked on TO
            if (openedFrom === 'to' && !selectedDate.to) {
                handleClosePopper()
                return
            }

            // comparing FROM values because DayPicker by default modify TO date
            if (selectedDate.from && isEqual(value.from, selectedDate.from)) {
                const newDate = selectedDate.to

                // edge case: if FROM date changed after TO
                if (
                    openedFrom === 'from' &&
                    newDate &&
                    isBefore(value.to, newDate)
                ) {
                    handleChange({ to: newDate })
                } else {
                    handleChange({ [openedFrom]: newDate })
                }

                handleClosePopper()
                return
            }

            // DayPicker changes FROM date only if selected date is before actual FROM
            const newDate = selectedDate?.from
            handleChange({ from: newDate })
            handleClosePopper()
            return
        }

        const newRange = {
            from: selectedDate?.from || value?.from,
            to: selectedDate?.to || value?.to,
        }

        handleChange(newRange)

        // close popper if range selected or value exists
        if (newRange?.from && newRange?.to) {
            handleClosePopper()
        }
    }

    const setSelectedDays = (days: string[]) => {
        handleChange({ days })
    }

    const handleClickOutside = (e: Event) => {
        if (
            ref.current &&
            e.target instanceof Node &&
            !ref.current.contains(e.target)
        ) {
            if (popperState.isOpen) {
                !!onBlur && onBlur()
                handleClosePopper()
            }
        }
    }

    useEventListener('mousedown', handleClickOutside, [
        popperState.isOpen,
        onBlur,
    ])

    return (
        <div ref={ref}>
            <RangeDatePickerInput
                rangeValue={value}
                onClick={handleOnRangeClick}
                onClear={onClear}
                placeholderFrom={placeholderFrom}
                placeholderTo={placeholderTo}
                active={popperState.isOpen && !disabled}
                openedFrom={openedFrom}
                disabled={disabled}
                hasError={hasError}
                onSelect={onSelect}
                onChange={onInputChange}
                ref={popperRef}
            />
            {!disabled && popperState.isOpen && (
                <div
                    tabIndex={-1}
                    style={popper.styles.popper}
                    className="z-20"
                    {...popper.attributes.popper}
                    ref={setPopperElement}
                    role="dialog"
                >
                    <div
                        className={cx('root', {
                            isMultipleMonths,
                        })}
                    >
                        {sideMenu && (
                            <div className={styles.sideMenu}>
                                <DayButtons
                                    value={value}
                                    onChange={onRangeButtonChange}
                                />
                                <DaysCheckboxDropdown
                                    selectedDays={dateRange.days || []}
                                    setSelectedDays={setSelectedDays}
                                />
                            </div>
                        )}
                        <DayPicker
                            {...datePickerConfig}
                            numberOfMonths={isMultipleMonths ? 2 : 1}
                            mode="range"
                            defaultMonth={value?.from}
                            selected={value}
                            onSelect={onSelect}
                            disabled={
                                disabledBefore && disabledAfter
                                    ? [
                                          { before: disabledBefore },
                                          { after: disabledAfter },
                                      ]
                                    : disabledBefore
                                    ? { before: disabledBefore }
                                    : disabledAfter
                                    ? { after: disabledAfter }
                                    : undefined
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default RangeDatePicker
