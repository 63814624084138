import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useResetPassword } from '../api'
import ResetPassword from '../components/ResetPassword'

import type { ResetPasswordForm, FormSubmitFn } from '../types'

export default function ResetPasswordContainer() {
    const [searchParams] = useSearchParams()
    const { mutate: setNewPassword, isSuccess } = useResetPassword()

    const handleSubmit: FormSubmitFn<ResetPasswordForm> = (
        values,
        formikHelpers
    ) => {
        setNewPassword(values, {
            onSuccess: () => {
                formikHelpers.setSubmitting(false)
            },
            onError: (error) => {
                formikHelpers.setSubmitting(false)
                formikHelpers.setErrors(error.errors)
            },
        })
    }

    return (
        <ResetPassword
            token={searchParams.get('token') || ''}
            email={searchParams.get('email') || ''}
            isSuccess={isSuccess}
            handleSubmit={handleSubmit}
        />
    )
}