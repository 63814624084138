import { ResponseList } from 'api/types'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'

type OptionsProps<T> = {
    name: string
    open: boolean
    searchPhrase: string
    placeholder?: string
    queryFn: (filters?: {}) => Promise<ResponseList<T[]>>
    queryFilters?: {}
}

export function useInfiniteDropdown<T>({
    name,
    open,
    queryFn,
    searchPhrase,
    queryFilters = {},
}: OptionsProps<T>) {
    const observerRef = useRef(null)
    const queryResult = useInfiniteQuery(
        `dropdown-${name}`,
        ({ pageParam }) =>
            queryFn({
                ...queryFilters,
                page: pageParam,
            }),
        {
            enabled: open,
            staleTime: 0,
            getNextPageParam: (lastPage) => {
                return lastPage.meta.current_page + 1 <= lastPage.meta.last_page
                    ? lastPage.meta.current_page + 1
                    : undefined
            },
        }
    )

    const debounceRefetch = useRef(
        debounce(() => {
            queryResult.refetch({
                refetchPage: () => true,
            })
        }, 500)
    ).current

    useEffect(() => {
        open && debounceRefetch()
    }, [searchPhrase]) // eslint-disable-line

    useEffect(() => {
        if (!open && searchPhrase) {
            queryResult.remove()
        }
    }, [open, searchPhrase]) // eslint-disable-line

    const handleObserver = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            const [target] = entries

            if (
                target.isIntersecting &&
                queryResult.hasNextPage &&
                !queryResult.isFetchingNextPage
            ) {
                queryResult.fetchNextPage()
            }
        },
        [
            queryResult.hasNextPage,
            queryResult.isFetchingNextPage,
            queryResult.fetchNextPage,
        ] // eslint-disable-line
    )

    useEffect(() => {
        if (!observerRef.current) {
            return
        }

        const element = observerRef.current
        const option = { threshold: 0 }

        const observer = new IntersectionObserver(handleObserver, option)
        observer.observe(element)
        return () => observer.unobserve(element)
    })

    const results = queryResult.data
        ? queryResult.data.pages
              .flat()
              .reduce((acc, item) => [...acc, ...item.data], [] as T[])
        : null

    return {
        observerRef,
        queryResult,
        results,
    }
}
