export const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            left: 0,
        },
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                borderRadius: 3,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#6B7280',
            displayColors: false,
            titleColor: '#E5E7EB',
            titleFont: {
                size: 12,
            },
            bodyColor: '#FFF',
            bodyFont: {
                size: 14,
            },
            callbacks: {
                afterTitle: (item: any) => item[0]?.dataset?.label,
                label: (item: any) =>
                    item?.dataset?.data.find(
                        (data: number | null) => data != null
                    ),
            },
        },
    },
    scales: {
        x: {
            border: {
                display: true,
                width: 3,
                color: '#9CA3AF',
                z: 1,
            },
            ticks: {
                display: true,
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                tickColor: '#9CA3AF',
                tickLength: 15,
                tickWidth: 3,
                offset: false,
            },
            offset: true,
        },
        y: {
            border: {
                display: false,
            },
            ticks: {
                padding: 0,
                display: true,
                color: '#F9FAFB',
                major: {
                    enabled: false,
                },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: true,
                color: '#374151',
                tickLength: 15,
                tickWidth: 0,
            },
        },
    },
}

export const seriesChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'line',
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                borderRadius: 3,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#6B7280',
        },
    },
    scales: {
        x: {
            border: {
                display: true,
                width: 3,
                color: '#9CA3AF',
                z: 1,
            },
            ticks: {
                display: true,
                color: '#9CA3AF',
                font: {
                    size: 12,
                },
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                tickColor: '#9CA3AF',
                tickLength: 15,
                tickWidth: 3,
            },
        },
        y: {
            border: {
                display: false,
            },
            ticks: {
                display: true,
                color: '#F9FAFB',
                font: {
                    size: 14,
                },
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                color: '#374151',
                tickLength: 15,
                tickWidth: 0,
            },
        },
    },
}

export const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    type: 'pie',
    plugins: {
        title: {
            display: false,
        },
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 12,
                boxHeight: 12,
                borderRadius: 6,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        tooltip: {
            backgroundColor: '#6B7280',
            displayColors: false,
            titleColor: '#E5E7EB',
            titleFont: {
                size: 12,
            },
            bodyColor: '#FFF',
            bodyFont: {
                size: 14,
            },
            callbacks: {
                label: (item: any) => item.formattedValue,
            },
        },
    },
}

export const transposedBarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                boxWidth: 6,
                boxHeight: 6,
                borderRadius: 3,
                useBorderRadius: true,
            },
            color: '#9CA3AF',
            font: {
                size: 16,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#6B7280',
            displayColors: false,
            titleColor: '#E5E7EB',
            titleFont: {
                size: 12,
            },
            bodyColor: '#FFF',
            bodyFont: {
                size: 14,
            },
            callbacks: {
                afterTitle: (item: any) => item[0]?.dataset?.label,
                label: (item: any) =>
                    item?.dataset?.data.find(
                        (data: number | null) => data != null
                    ),
            },
        },
    },
    scales: {
        y: {
            border: {
                display: false,
            },
            ticks: {
                display: true,
                major: {
                    enabled: false,
                },
            },
            grid: {
                display: true,
                tickColor: '#9CA3AF',
                tickLength: 15,
                tickWidth: 0,
            },
            offset: true,
        },
        x: {
            border: {
                display: true,
                width: 3,
                color: '#9CA3AF',
                z: 1,
            },
            ticks: {
                display: true,
                color: '#F9FAFB',
                major: {
                    enabled: false,
                },
                font: {
                    size: 14,
                },
            },
            grid: {
                display: true,
                color: '#374151',
                tickLength: 0,
                tickWidth: 0,
            },
        },
    },
    indexAxis: 'y',
}
