import { useMemo } from 'react'

import { useUserQuery, usePlotterQuery } from '../api'
import { DropdownItem } from '../types'
import { useCitiesQuery, useCountriesQuery } from '../api/endpoints/places'
import { useTranslation } from 'react-i18next'
import { useUserRoles } from './useUserRoles'
import { DEVICE_TYPES, FOIL_SIZES, FOIL_TYPES } from '../constants'

export const useAllItem = () => {
    const { t } = useTranslation()
    const ALL_ITEM: DropdownItem = useMemo(
        () => ({ id: '', name: t('dictionary.all') }),
        [t]
    )
    return ALL_ITEM
}

export const useSupervisorsDropdown = (): DropdownItem[] => {
    const userRoles = useUserRoles()
    const usersToAssign = useUserQuery({ roles: [userRoles.supervisor.id] })

    const userDropdownValues = useMemo(
        () =>
            usersToAssign.data?.data.map((user) => ({
                name: `${user.first_name} ${user.last_name}`,
                description: user.name,
                id: user.id,
            })) || [],
        [usersToAssign]
    )

    return userDropdownValues
}

export const useUsersDropdown = (): DropdownItem[] => {
    const userRoles = useUserRoles()
    const usersToAssign = useUserQuery({ roles: [userRoles.user.id] })

    const userDropdownValues = useMemo(
        () =>
            usersToAssign.data?.data.map((user) => ({
                name: user.name
                    ? user.name
                    : `${user.first_name} ${user.last_name}`,
                description: user.email,
                id: user.id,
            })) || [],
        [usersToAssign]
    )

    return userDropdownValues
}

export const usePlottersDropdown = (): DropdownItem[] | undefined => {
    const plottersToAssign = usePlotterQuery({ assigned: false })

    const plotterDropdownValues = useMemo(
        () =>
            plottersToAssign.data?.data.map((plotter) => ({
                name: plotter.name,
                id: plotter.id,
            })),
        [plottersToAssign]
    )

    return plotterDropdownValues
}

export const useCountriesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const countriesQuery = useCountriesQuery()

    const countriesDropdownValues = useMemo(
        () =>
            countriesQuery.data?.data.map((country) => ({
                name: t(`country.${country.name}`),
                id: country.id,
            })) || [],
        [countriesQuery.data?.data, t]
    )

    return countriesDropdownValues
}

export const useFoilTypesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const foilTypesDropdownValues = useMemo(
        () =>
            FOIL_TYPES.map((foilType) => ({
                name: t(`protection_films_types.${foilType}`),
                id: foilType,
            })) || [],
        [t]
    )

    return foilTypesDropdownValues
}

export const useFoilSizesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const foilSizesDropdownValues = useMemo(
        () =>
            FOIL_SIZES.map((foilSize) => ({
                name: t(`protection_films_sizes.${foilSize}`),
                id: foilSize,
            })) || [],
        [t]
    )

    return foilSizesDropdownValues
}

export const useDeviceTypesDropdown = (): DropdownItem[] => {
    const { t } = useTranslation()
    const deviceTypesDropdownValues = useMemo(
        () =>
            DEVICE_TYPES.map((deviceType) => ({
                name: t(`device_type.${deviceType}`),
                id: deviceType,
            })) || [],
        [t]
    )

    return deviceTypesDropdownValues
}

type Places =
    | {
          isLoading: true
          isError: false
          isSuccess: false
      }
    | {
          cities: DropdownItem[]
          countries: DropdownItem[]
          isLoading: false
          isError: false
          isSuccess: true
      }
    | {
          isError: true
          isLoading: false
          isSuccess: false
          error?: string
      }

export const usePlaces = (): Places => {
    const { t } = useTranslation()
    const countriesQuery = useCountriesQuery()
    const citiesQuery = useCitiesQuery()

    const countriesDropdownValues = useMemo(
        () =>
            countriesQuery.data?.data.map((country) => ({
                name: t(`country.${country.name}`),
                id: country.id,
            })) || [],
        [countriesQuery.data?.data, t]
    )

    if (countriesQuery.isLoading || citiesQuery.isLoading) {
        return {
            isSuccess: false,
            isError: false,
            isLoading: true,
        }
    }

    if (countriesQuery.isError || citiesQuery.isError) {
        return {
            isSuccess: false,
            isError: true,
            isLoading: false,
            error: countriesQuery.error?.message || citiesQuery.error?.message,
        }
    }

    return {
        cities: citiesQuery.data?.data || [],
        countries: countriesDropdownValues || [],
        isLoading: false,
        isError: false,
        isSuccess: true,
    }
}
