const pl = {
    'sidebar.users': 'Użytkownicy',
    'sidebar.clients': 'Klienci',
    'sidebar.supervisors': ' Opiekunowie',
    'sidebar.brand': 'Producenci',
    'sidebar.patterns': 'Wzorce',
    'sidebar.plotters': 'Plotery',
    'sidebar.stats': 'Statystyki',
    'sidebar.report': 'Raport szczegóły',
    'sidebar.qr': 'Generator kodów',
    'sidebar.device': 'Urządzenia',
    'auth.logout': 'Wyloguj',
    'auth.email': 'E-mail',
    'auth.password': 'Hasło',
    'auth.repeat_password': 'Powtórz hasło',
    'auth.remind': 'Przypomnij hasło',
    'auth.login': 'Zaloguj',
    'auth.title': 'Zaloguj się do serwisu Hammer Glass',
    'auth.remind.title':
        'Przypomnienie hasła odbywa się za pomocą linku aktywacyjnego.',
    'auth.remind.subtitle': 'Podaj adres e-mail konta.',
    'auth.remind.send': 'Wyślij link do przypomnienia hasła',
    'auth.remind.success.title': 'Link został wysłany',
    'auth.remind.success.subtitle':
        'Przejdź do swojej skrzynki pocztowej i potwierdź zmianę hasła, klikając w otrzymany link.',
    'auth.reset.title': 'Nowe hasło',
    'auth.reset.save': 'Zapisz',
    'plotter.list.title': 'Lista ploterów tnących',
    'user.list.title': 'Lista użytkowników',
    'dictionary.active': 'Aktywny',
    'dictionary.inactive': 'Nieaktywny',
    'dictionary.user': 'Użytkownik Hurtowy',
    'dictionary.user_short': 'Użytkownik',
    'dictionary.user_description': 'Sieci handlowe',
    'dictionary.supervisor': 'Opiekun',
    'dictionary.supervisor_short': 'Opiekun',
    'dictionary.supervisor_description':
        'Opiekun regionu. Może aktywować urządzenia, dodawać użytkowników hurtowych oraz przypisywać plotery.',
    'dictionary.admin': 'Administrator',
    'dictionary.admin_short': 'Admin',
    'dictionary.admin_description':
        'Posiada dostęp do wszystkiego w systemie. Może dodawać, edytować, zapisywać oraz usuwać.',
    'dictionary.all': 'Wszystko',
    'dictionary.has': 'Posiada',
    'dictionary.hasnot': 'Brak',
    'filters.role': 'Rola',
    'filters.status': 'Status',
    'filters.country': 'Kraj',
    'filters.superior': 'Opiekun',
    'filters.region': 'Region',
    'filters.city': 'Miasto',
    'filters.device': 'Urządzenie',
    'filters.film': 'Typ',
    'filters.size': 'Folia',
    'filters.pattern': 'Wzorzec',
    'filters.patterns': 'Wzorce',
    'filters.brand': 'Producent',
    'filters.type': 'Typ',
    'action.user.create': 'Dodaj użytkownika',
    'action.plotter.create': 'Dodaj ploter',
    'action.brand.create': 'Dodaj producenta',
    'action.device.create': 'Dodaj urządzenie',
    'action.pattern.create': 'Dodaj wzorzec',
    'action.filters': 'Filtry',
    'action.export': 'Exportuj XLS',
    'action.search': 'Szukaj',
    'action.delete': 'Usuń',
    'list.user.id': 'Id',
    'list.user.name': 'Nazwa',
    'list.user.email': 'E-mail',
    'list.user.country': 'Kraj',
    'list.user.role': 'Rola',
    'list.user.status': 'Status',
    'list.user.empty_list': 'Nie ma jeszcze żadnych użytkowników.',
    'list.user.empty_search': 'Nie znaleziono wyników wyszukiwania.',
    'list.user.edit': 'Edytuj',
    'list.user.send_password': 'Wyślij hasło',
    'list.user.stats': 'Statystyki',
    'list.user.deactivate': 'Dezaktywuj',
    'list.user.remove': 'Usuń',
    'form.user.new.title': 'Dodaj użytkownika',
    'form.user.field.first_name': 'Imię',
    'form.user.field.last_name': 'Nazwisko',
    'form.user.field.phone': 'Telefon',
    'form.user.field.email': 'E-mail',
    'form.user.field.name': 'Nazwa',
    'form.user.field.supervisor': 'Opiekun',
    'form.user.field.language': 'Język',
    'form.user.field.role': 'Rola',
    'form.user.field.country': 'Kraj',
    'form.user.field.region': 'Region',
    'form.user.field.city': 'Miasto',
    'form.user.title.plotters': 'Plotery',
    'form.user.tabs.data': 'Dane',
    'form.user.tabs.features': 'Cechy',
    'form.user.tabs.users': 'Użytkownicy',
    'form.user.add_plotter': 'Dodaj ploter',
    'form.plotter.title': 'Dodaj ploter',
    'form.plotter.edit_title': 'Edytuj ploter',
    'form.plotter.field.name': 'Nazwa',
    'form.plotter.field.description': 'Opis',
    'form.plotter.field.serial': 'Numer seryjny',
    'form.brand.title': 'Dodaj producenta',
    'form.brand.edit_title': 'Edytuj producenta',
    'form.brand.field.name': 'Nazwa',
    'form.brand.field.order': 'Kolejność',
    'form.device.title': 'Dodaj nowe urządzenie',
    'form.device.edit_title': 'Edytuj urządzenie',
    'form.device.field.name': 'Nazwa',
    'form.device.field.type': 'Typ',
    'form.device.field.brand': 'Producent',
    'form.device.field.date': 'Data premiery',
    'form.pattern.title': 'Dodaj wzorzec',
    'form.pattern.edit_title': 'Edytuj wzorzec',
    'form.pattern.field.name': 'Nazwa',
    'form.pattern.field.size': 'Rozmiar',
    'form.pattern.field.png': 'Plik PNG',
    'form.pattern.field.plt': 'Plik wzorca',
    'form.qr.title': 'Generator kodów',
    'form.qr.subtitle': 'Generator nowych kodów QR',
    'form.qr.field.size': 'Rozmiar',
    'form.qr.field.type': 'Rodzaj folii',
    'form.qr.field.count': 'Ilość folii',
    'form.export.title': 'Eksportuj XLS',
    'form.export.data_label': 'Zakres danych',
    'form.export.field.supervisor': 'Opiekun',
    'form.export.field.country': 'Kraj',
    'form.export.field.city': 'Miasto',
    'form.export.field.device': 'Urządzenie',
    'form.export.field.film': 'Folia',
    'form.export.field.type': 'Typ',
    'form.export.field.pattern': 'Wzorzec',
    'form.export.field.from': 'Od',
    'form.export.field.to': 'Do',
    'form.cancel': 'Anuluj',
    'form.save': 'Zapisz',
    'form.add': 'Dodaj',
    'form.export': 'Pobierz',
    'form.confirm': 'Zatwierdź',
    'form.generate': 'Generuj nowe kody',
    'list.plotter.id': 'Id',
    'list.plotter.name': 'Nazwa plotera',
    'list.plotter.owner': 'Właściciel',
    'list.plotter.description': 'Opis',
    'list.plotter.serial': 'Nr Seryjny',
    'list.plotter.status': 'Status',
    'list.plotter.empty_list': 'Brak ploterów',
    'list.plotter.empty_search': 'Brak wyników wyszukiwania.',
    'list.plotter.deactivate': 'Wyłącz',
    'list.plotter.delete': 'Usuń',
    'list.plotter.lock': 'Zablokuj',
    'list.plotter.edit': 'Edytuj',
    'list.plotter.assign': 'Przypisz do usera',
    'list.plotter.detach': 'Odepnij',
    'list.report.title': 'Raport szczegółowy',
    'list.report.data': 'Data',
    'list.report.client': 'Klient',
    'list.report.superior': 'Opiekun',
    'list.report.country': 'Kraj',
    'list.report.region': 'Region',
    'list.report.city': 'Miasto',
    'list.report.device': 'Urządzenie',
    'list.report.foil_type': 'Folia i typ',
    'list.report.pattern': 'Wzorzec',
    'list.report.empty_list': 'Nie ma jeszcze żadnych wycięć.',
    'list.report.empty_search': 'Brak wyników wyszukiwania.',
    'list.report.cutter': 'Ploter',
    'list.brand.title': 'Producenci',
    'list.brand.id': 'Id',
    'list.brand.logo': 'Logo',
    'list.brand.name': 'Nazwa producenta',
    'list.brand.order': 'Kolejność',
    'list.brand.devices': 'Urządzenia',
    'list.brand.devices_count': 'Urządzenia',
    'list.brand.empty_list': 'Nie ma jeszcze żadnych producentów.',
    'list.brand.empty_search': 'Brak wyników wyszukiwania.',
    'list.brand.edit': 'Edytuj',
    'list.brand.delete': 'Usuń',
    'list.device.title': 'Lista urządzeń',
    'list.device.id': 'Id',
    'list.device.image': 'Podgląd',
    'list.device.name': 'Urządzenie',
    'list.device.type': 'Typ',
    'list.device.date': 'Premiera',
    'list.device.patterns': 'Wzorce',
    'list.device.empty_list': 'Nie ma jeszcze żadnych urządzeń.',
    'list.device.empty_search': 'Brak wyników wyszukiwania.',
    'list.device.edit': 'Edytuj',
    'list.device.delete': 'Usuń',
    'list.pattern.title': 'Wzorce',
    'list.pattern.id': 'Id',
    'list.pattern.image': 'Podgląd',
    'list.pattern.name': 'Nazwa',
    'list.pattern.size': 'Rozmiar',
    'list.pattern.date': 'Data dodania',
    'list.pattern.device': 'Urządzenia',
    'list.pattern.brand': 'Producent',
    'list.pattern.type': 'Typ',
    'list.pattern.empty_list': 'Nie ma jeszcze żadnych wzorców.',
    'list.pattern.empty_search': 'Brak wyników wyszukiwania.',
    'list.pattern.edit': 'Edytuj',
    'list.pattern.delete': 'Usuń',
    'list.empty_list': 'Brak wyników',
    'footer.message': 'Wyświetlane są wyniki {{from}} do {{to}} z {{total}}',
    'info.user.updated': 'Dane użytkownika zostały zaktualizowane',
    'info.user.saved': 'Dane użytkownika zostały zapisane',
    'info.user.link_send': 'Link został wysłany',
    'info.plotter.saved': 'Dane plotera zostały zapisane',
    'info.plotter.updated': 'Ploter został zaktualizowany',
    'info.plotter.deleted': 'Ploter został usunięty',
    'info.user.deleted': 'Użytkownik został usunięty',
    'info.brand.saved': 'Dane producenta zostały zapisane',
    'info.brand.updated': 'Producent został zaktualizowany',
    'info.brand.deleted': 'Producent został usunięty',
    'info.device.saved': 'Dane urządzenie zostały zapisane',
    'info.device.updated': 'Urządzenie zostało zaktualizowane',
    'info.device.deleted': 'Urządzenie zostało usunięte',
    'info.pattern.saved': 'Dane wzorca zostały zapisane',
    'info.pattern.updated': 'Wzorzec został zaktualizowany',
    'info.pattern.deleted': 'Wzorzec został usunięty',
    'info.qr.saved': 'Wygenerowano nowe kody',
    'info.export.saved': 'Dane zostały pobrane',
    'info.export.failed': 'Wystąpił błąd pobierania',
    'deactivate.user.title': 'Dezaktywacja użytkownika',
    'deactivate.user.description':
        'Czy na pewno chcesz dezaktywować tego użytkownika?',
    'deactivate.user.action': 'Dezaktywuj',
    'delete.user.title': 'Usuń użytkownika',
    'delete.user.description': 'Czy na pewno chcesz usunąć tego użytkownika?',
    'delete.user.action': 'Usuń',
    'deactivate.plotter.title': 'Dezaktywacja plotera',
    'deactivate.plotter.description':
        'Czy na pewno chcesz dezaktywować ten ploter?',
    'deactivate.plotter.action': 'Dezaktywuj',
    'detach.plotter.title': 'Usuń plotter',
    'detach.plotter.description':
        'Czy na pewno chcesz usunąć plotter z tego użytkownika?.',
    'detach.plotter.action': 'Usuń',
    'delete.plotter.title': 'Usuń ploter',
    'delete.plotter.description': 'Czy na pewno chcesz usunąć ten plotter?.',
    'delete.brand.title': 'Usuń producenta',
    'delete.brand.description':
        'Usunięcie producenta jest możliwe gdy nie posiada żadnych danych. Czy chcesz usunąć?',
    'delete.device.title': 'Usuń urządzenie',
    'delete.device.description': 'Czy na pewno chcesz usunąć to urządzenie?',
    'delete.pattern.title': 'Usuń wzorzec',
    'delete.pattern.description': 'Czy na pewno chcesz usunąć ten wzorzec?',
    'assign.plotter.title': 'Dodaj ploter',
    'assign.plotter.description':
        'Możesz dodać jeden, bądź wiele ploterów. Dodając wiele zastosuj przecinek jako separator numerów seryjnych.',
    'assign.plotter.label': 'Nazwa / Numer seryjny',
    'attach.user.title': 'Dodaj użytkownika',
    'attach.user.description':
        'Wyszukaj już istniejących użytkowników i przypisz ich do konta opiekuna.',
    'attach.user.label': 'Nazwa użytkownika',
    'assign.user.title': 'Przypisz do użytkownika',
    'assign.user.description':
        'Wyszukaj już istniejącego użytkownika i przypisz do niego ploter.',
    'statistics.title': 'Statystyki',
    'statistics.bar_chart.title': 'Zużycie klientów',
    'statistics.series_chart.title': 'Typy folii',
    'statistics.summary.title': 'Podsumowanie ogólne',
    'statistics.data_link': 'Dane szczegółowe',
    'statistics.totals.protection_films_count': 'Zużytych folii',
    'statistics.totals.cutters_count': 'Aktywne plotery',
    'statistics.totals.clients_count': 'Klienci',
    'statistics.cutters_chart.title': 'Aktywne plotery',
    'statistics.pie_chart.title': 'Rodzaje urządzeń',
    'statistics.brands_chart.title': 'Top 10 Producentów',
    'statistics.devices_chart.title': 'Top 10 Urządzeń',
    'protection_films_sizes.A': 'A - Folia mała',
    'protection_films_sizes.B': 'B - Folia średnia',
    'protection_films_sizes.C': 'C - Folia duża',
    'protection_films_types.1': 'Matte anti-crash',
    'protection_films_types.2': '150um glossy',
    'protection_films_types.3': '210um glossy',
    'protection_films_types.4': 'Privacy',
    'protection_films_types.5': 'UV',
    'protection_films_types.6': 'UV Privacy',
    'protection_films_types.7': 'Unknown 1',
    'protection_films_types.8': 'Unknown 2',
    'cutters_series.actual_cuters': 'Aktualne',
    'cutters_series.compared_cuters': 'Od {{from}} do {{to}}',
    'qrgenerator.summary.for_use': 'Pozostało folii',
    'qrgenerator.summary.used': 'Wykorzystane folie',
    'qrgenerator.summary.total_count': 'Całkowita liczba folii',
    'qrgenerator.summary.title': 'Podsumowanie',
    'device_type.phone': 'Telefon',
    'device_type.tablet': 'Tablet',
    'device_type.watch': 'Zegarek',
    'device_type.other': 'Inne',
    'country.PL': 'Polska',
    'country.DE': 'Niemcy',
    'country.CZ': 'Czechy',
    'country.BF': 'Burkina Faso',
    'country.SK': 'Słowacja',
    'country.IL': 'Izrael',
    'bread.user_list': 'Lista użytkowników',
    'bread.device': 'Urządzenia',
    'bread.pattern': 'Wzorce',
    'day.monday': 'Pon',
    'day.tuesday': 'Wt',
    'day.wednesday': 'Śr',
    'day.thursday': 'Czw',
    'day.friday': 'Pt',
    'day.saturday': 'Sob',
    'day.sunday': 'Niedz',
    'dropzone.text_bold': 'Wybierz plik',
    'dropzone.text_normal': 'albo przeciągnij i upuść',
    'dropzone.limit': ' up to 2MB',
}

export default pl
