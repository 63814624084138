import { useQuery } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_ROLES_KEY } from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type { ResponseList, ResponseError, RoleListResponse } from '../types'

export const getRoles = <T>(filters?: {}): Promise<T> =>
    client.get('/roles' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useRolesQuery = <T = ResponseList<RoleListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_ROLES_KEY, 'index', filters],
        () => getRoles<T>({ ...filters }),
        options
    )
