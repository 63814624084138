import React, { useEffect } from 'react'

export const useEventListener = (
    eventType: string,
    callback: (e: Event) => void,
    deps?: React.DependencyList
) => {
    deps = deps || []

    useEffect(() => {
        document.addEventListener(eventType, callback)
        return () => document.removeEventListener(eventType, callback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
