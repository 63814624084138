import React from 'react'
import { FormikErrors, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, Label } from './ui'
import { TextField } from './forms'

import type { DropdownItem, UserForm } from '../types'

type UserProps = {
    values: UserForm
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<UserForm>>
    isSubmitting: boolean
    readonly?: boolean
    cities: DropdownItem[]
    countries: DropdownItem[]
}

export default function UserFeatures({
    values,
    setFieldValue,
    isSubmitting,
    readonly = false,
    cities,
    countries,
}: UserProps) {
    const { t } = useTranslation()

    return (
        <Form>
            <div className="mt-8">
                <div className="">
                    <div className="">
                        <div className="border-t border-gray-600 my-5"></div>
                        <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.user.field.country')}</Label>
                                <Dropdown
                                    value={values.country}
                                    items={countries}
                                    disabled={readonly}
                                    onChange={(value) =>
                                        setFieldValue('country', value)
                                    }
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.user.field.region')}</Label>
                                <TextField name="region" disabled={readonly} />
                            </div>
                        </div>
                        <div className="border-t border-gray-600 my-5"></div>
                        <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.user.field.city')}</Label>
                                <Dropdown
                                    value={values.city}
                                    items={cities}
                                    disabled={readonly}
                                    onChange={(value) =>
                                        setFieldValue('city', value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="border-t border-gray-600 my-5"></div>
                    </div>
                </div>
                {!readonly && (
                    <div className="flex mt-8 pt-5 items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <Button variant="secondary" as="link" to="/user">
                                {t('form.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.save')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Form>
    )
}
