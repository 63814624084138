import { useMemo, useState } from 'react'
import { DropdownItem } from 'types'

export function useDropdownLocalSearch(items: DropdownItem[]) {
    const [searchPhrase, setSearchPhrase] = useState('')

    const filteredItems = useMemo(() => {
        const pattern = searchPhrase.toLowerCase()
        return items
            .map((val) => ({
                id: val.id,
                name: val.name,
                description: val.description,
            }))
            .filter((val) => {
                return val.name.toLowerCase().search(pattern) !== -1
            })
    }, [items, searchPhrase])

    return {
        filteredItems,
        searchPhrase,
        setSearchPhrase,
        onBlur: () => setSearchPhrase(''),
    }
}
