import { useMutation } from 'react-query'

import client from '../client'

import type { UseMutationResult } from 'react-query'
import type {
    ResponseError,
    LogoutRequest,
    LogoutResponse,
    ResponseData,
} from '../types'

const logout = (data: LogoutRequest): Promise<ResponseData<LogoutResponse>> =>
    client.post('/authenticate/logout', data)

export const useLogout = (): UseMutationResult<
    ResponseData<LogoutResponse>,
    ResponseError
> => useMutation(() => logout({}))
