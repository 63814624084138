import { ProtectionFilmsResponse } from 'api/types'
import { useTranslation } from 'react-i18next'

export function UsageCounter({
    Icon,
    value,
    label,
}: {
    Icon: (props: React.ComponentProps<'svg'>) => JSX.Element
    value?: number
    label: string
}) {
    return (
        <span className="relative flex flex-col w-full rounded-lg items-center pt-10 px-6 pb-8 bg-gray-800">
            <div className="absolute top-[-24px] p-3 rounded-lg bg-red-600">
                <Icon className="w-6 h-6" />
            </div>
            <p className="text-2xl leading-8 font-semibold">{value || 0}</p>
            <h5 className="text-base leading-6 font-normal pt-4  text-gray-300">
                {label}
            </h5>
        </span>
    )
}

export function UsageCounterContainer({
    children,
}: {
    children: React.ReactNode
}) {
    return (
        <div className="h-[144px] w-full flex justify-between gap-6">
            {children}
        </div>
    )
}

export function DetailedUsageCounters({
    protectionFilmsSizes,
    protectionFilmsTypes,
}: {
    protectionFilmsSizes: ProtectionFilmsResponse['protection_films_sizes']
    protectionFilmsTypes: ProtectionFilmsResponse['protection_films_types']
}) {
    const { t } = useTranslation()

    return (
        <div className="h-[192px] w-full flex justify-between gap-6">
            {protectionFilmsSizes.map((filmSize, i) => (
                <span
                    key={i}
                    className="flex flex-col w-full max-w-[517px] rounded-lg pt-10 px-6 pb-8 bg-gray-900 border-2 border-gray-700 gap-4"
                >
                    <div className="flex items-center">
                        <span className="text-2xl leading-8 font-semibold text-gray-100">
                            {filmSize.count}
                        </span>
                        <span className="ml-4 text-base leading-6 font-normal text-gray-300">
                            {t(`protection_films_sizes.${filmSize.size}`)}
                        </span>
                    </div>
                    <div className="border-b border-gray-600 w-full"></div>
                    <div className="flex gap-4 items-center justify-between">
                        {protectionFilmsTypes[filmSize.size].map(
                            (type, index) => (
                                <span key={index} className="flex items-center">
                                    {index !== 0 ? (
                                        <span className="h-10 border-r border-gray-600 mr-4"></span>
                                    ) : null}
                                    <span>
                                        <p className="text-2xl leading-8 font-semibold text-gray-300 pb-2">
                                            {type.count}
                                        </p>
                                        <p className="text-base leading-6 font-normal text-gray-400">
                                            {t(
                                                `protection_films_types.${type.type}`
                                            )}
                                        </p>
                                    </span>
                                    {/* {index !=
                                    protectionFilmsTypes[filmSize.size].length -
                                        1 ? (
                                        <span className="h-10 border-r border-gray-600"></span>
                                    ) : null} */}
                                </span>
                            )
                        )}
                    </div>
                </span>
            ))}
        </div>
    )
}
