import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useQueryClient } from 'react-query'

import { QUERY_CACHE_USERS_KEY } from '../../constants/index'
import { useUpdateUser } from '../../api'
import { useFlashMessage, useModal } from '..'
import { useCurrentUser } from '../useCurrentUser'

export function useUserActiveForm() {
    const { t } = useTranslation()

    const flashMessage = useFlashMessage()
    const queryClient = useQueryClient()
    const profileData = useCurrentUser()

    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })
    const confirmModal = useModal<number>()

    const form = useFormik({
        initialValues: {
            active: false,
        },
        onSubmit: (values, formikHelpers) => {
            updateUserQuery.mutate(
                {
                    id: String(confirmModal.getState()!),
                    data: {
                        active: values.active,
                    },
                },
                {
                    onSuccess: async () => {
                        confirmModal.closeModal()
                        flashMessage({
                            type: 'success',
                            content: t('info.user.updated'),
                        })
                        formikHelpers.setSubmitting(false)
                        await queryClient.invalidateQueries([
                            QUERY_CACHE_USERS_KEY,
                            'index',
                        ])
                        if (
                            profileData.data?.data.id == confirmModal.getState()
                        ) {
                            await queryClient.invalidateQueries(['profile'])
                        }
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        },
    })

    return {
        modal: confirmModal,
        form: form,
    }
}
