import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spinner } from '../components/ui'
import { useAuth } from '../hooks'
import { useLogout } from '../api/endpoints/logout'

export default function LogoutContainer() {
    const navigate = useNavigate()
    const auth = useAuth()
    const { mutate: logout } = useLogout()

    useEffect(() => {
        logout(null)
        auth.clearToken()
        navigate('/login', { replace: true })
    }, [navigate, auth, logout])

    return (
        <div className="w-full h-full flex justify-center items-center">
            <Spinner />
        </div>
    )
}
