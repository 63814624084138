import { useState } from 'react'

export function useDropdownSearch() {
    const [searchPhrase, setSearchPhrase] = useState('')

    return {
        searchPhrase,
        setSearchPhrase,
        onClose: () => setSearchPhrase(''),
    }
}
