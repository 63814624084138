import React from 'react'
import { DotsVerticalIcon } from '@heroicons/react/solid'

import type { ButtonProps } from './Button'
import classNames from 'classnames'

export type Button3dotsProps = {
    disabled?: ButtonProps['loading']
    loading?: ButtonProps['loading']
    // onClick: () => void
    open?: boolean
}

export default function Button3dots({
    loading,
    disabled,
    open, // onClick,
}: Button3dotsProps) {
    return (
        <button
            type="button"
            // onClick={onClick}
            className={classNames({
                'bg-gray-800 ring-2 ring-red-600 ring-offset-2 ring-offset-[#141313] rounded-lg':
                    open,
            })}
            disabled={disabled || loading}
        >
            <div>
                <DotsVerticalIcon className="w-auto h-4" />
            </div>
        </button>
    )
}
