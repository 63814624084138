import moment from 'moment'

export const lowerLimitDate = moment(new Date()).subtract(1, 'year').toDate()
export const upperLimitDate = new Date()

export const defaultFrom = moment(new Date())
    .subtract(1, 'month')
    .add(1, 'day')
    .toDate()
export const defaultTo = upperLimitDate
