import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import { Button3dots } from './ui'
import Menu3dots from './Menu3dots'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import { PencilAltIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'

import type { UseQueryResult } from 'react-query'
import type { PatternListFilterState, PatternListFilterAction } from '../types'
import type {
    ResponseList,
    PatternListResponse,
    PatternResponse,
} from '../api/types'

type PatternListProps = {
    patternsQuery: UseQueryResult<ResponseList<PatternListResponse>>
    filters: PatternListFilterState
    filterCount: number
    filterAction?: React.Dispatch<PatternListFilterAction>
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: PatternResponse) => void
    extendedData: boolean
}

type TableProps = {
    data: PatternListResponse
    handleRemove?: (id: number) => void
    handleEdit?: (id: number, payload: PatternResponse) => void
    extendedData: boolean
}

const PatternList: React.FC<PatternListProps> = ({
    patternsQuery,
    filters,
    filterCount,
    filterAction,
    handleRemove,
    handleEdit,
    extendedData,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-[65px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.pattern.id')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.pattern.image')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.pattern.name')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.pattern.size')}
                    </th>
                    {extendedData && (
                        <>
                            <th
                                scope="col"
                                className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                            >
                                {t('list.pattern.device')}
                            </th>
                            <th
                                scope="col"
                                className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                            >
                                {t('list.pattern.brand')}
                            </th>
                            <th
                                scope="col"
                                className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                            >
                                {t('list.pattern.type')}
                            </th>
                        </>
                    )}
                    <th
                        scope="col"
                        className="w-1 min-w-[120px] px-6 py-4 text-left tracking-wider box-content"
                    >
                        {t('list.pattern.date')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-4 text-left tracking-wider"
                    ></th>
                </TableHeader>
                {patternsQuery.isSuccess && patternsQuery.isFetched && (
                    <>
                        {patternsQuery.data.meta.total > 0 && (
                            <TableBody
                                data={patternsQuery.data.data}
                                handleRemove={handleRemove}
                                handleEdit={handleEdit}
                                extendedData={extendedData}
                            />
                        )}
                    </>
                )}
                {patternsQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 h-14 relative">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.pattern.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.pattern.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            {extendedData && (
                                <>
                                    <td className="px-6 py-4">
                                        <span></span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span></span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <span></span>
                                    </td>
                                </>
                            )}
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {patternsQuery.isFetching && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            {filterAction && (
                <TableFooter
                    meta={patternsQuery.data?.meta}
                    filterAction={filterAction}
                />
            )}
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({
    data,
    handleRemove,
    handleEdit,
    extendedData,
}) => {
    const { t } = useTranslation()

    const isMutating = false
    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>{item.id}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span className="block w-10 h-10 relative">
                            {item.svg_url ? (
                                <img
                                    className="max-h-full m-auto absolute top-0 bottom-0 left-0 right-0"
                                    alt={item.name}
                                    style={{
                                        filter: 'brightness(0) invert(1)',
                                    }}
                                    src={item.svg_url}
                                />
                            ) : (
                                <span />
                            )}
                        </span>
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.name}</span>
                    </td>
                    <td className="px-6 py-4">
                        {t(`protection_films_sizes.${item.size}`)}
                    </td>
                    {extendedData && (
                        <>
                            <td className="px-6 py-4">{item.device || '-'} </td>
                            <td className="px-6 py-4">{item.brand || '-'}</td>
                            <td className="px-6 py-4">
                                {item.type
                                    ? t(`device_type.${item.type}`)
                                    : '-'}
                            </td>
                        </>
                    )}
                    <td className="px-6 py-4">
                        {item.created_at
                            ? moment(item.created_at).format('DD-MM-YYYY')
                            : '-'}
                    </td>
                    <td className="px-6 py-4 text-right">
                        <Menu3dots>
                            {({ open }) => (
                                <>
                                    <Menu3dots.Button
                                        as={
                                            isMutating ? React.Fragment : 'span'
                                        }
                                    >
                                        <Button3dots
                                            open={open}
                                            loading={isMutating}
                                        />
                                    </Menu3dots.Button>
                                    <Menu3dots.Items>
                                        <>
                                            {handleEdit && (
                                                <div className="divide-y border-t-[1px] border-gray-700 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleEdit
                                                                ? handleEdit(
                                                                      item.id,
                                                                      item
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <PencilAltIcon className="w-5 h-5 mr-3" />
                                                        {t('list.pattern.edit')}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}

                                            {handleRemove && (
                                                <div className="divide-y border-t-[1px] border-gray-700 divide-gray-200 py-1">
                                                    <Menu3dots.Item
                                                        onClick={() =>
                                                            handleRemove
                                                                ? handleRemove(
                                                                      item.id
                                                                  )
                                                                : {}
                                                        }
                                                    >
                                                        <TrashIcon className="w-5 h-5 mr-3" />
                                                        {t(
                                                            'list.pattern.delete'
                                                        )}
                                                    </Menu3dots.Item>
                                                </div>
                                            )}
                                        </>
                                    </Menu3dots.Items>
                                </>
                            )}
                        </Menu3dots>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default PatternList
