import React, { Fragment } from 'react'
import { MenuItems, Transition } from '@headlessui/react'

const Menu3dotsItems: React.FC<{ children: React.ReactElement }> = ({
    children,
}) => {
    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <MenuItems className="origin-bottom-right absolute right-0 w-44 mt-1 border-[1px] border-gray-700 rounded-md shadow-lg bg-gray-600 focus:outline-none z-50">
                {children}
            </MenuItems>
        </Transition>
    )
}

export default Menu3dotsItems
