import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Loader } from './components/ui'
import { useAuth } from './hooks'
import { useCurrentUser } from './hooks/useCurrentUser'
import { useChangeLang } from './hooks/useChangeLang'

export default function AuthRoutes() {
    const auth = useAuth()
    const user = useCurrentUser()

    const authToken = auth.getToken()

    useChangeLang(user.data?.data.lang)

    if (!authToken) {
        return <Navigate to="/login" />
    }

    if (user.isLoading) {
        return <Loader />
    }

    if (user.isError) {
        return <div>{user.error.message}</div>
    }

    if (user.isSuccess) {
        return <Outlet />
    }

    return null
}
