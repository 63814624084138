import React, { Fragment } from 'react'
import { ComboboxOptions, Transition } from '@headlessui/react'

interface ComboboxProps {
    children?: React.ReactNode
    open: boolean
}

const DropdownComboboxOptions = (props: ComboboxProps) => {
    return (
        <Transition
            show={props.open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <ComboboxOptions className="absolute z-10 mt-1 w-full bg-gray-600 text-gray-50 border border-gray-800 shadow-sm max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
                {props.children}
            </ComboboxOptions>
        </Transition>
    )
}

export default DropdownComboboxOptions
