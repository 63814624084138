import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'

import { getCxFromStyles, plotterMapFn } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'

import type {
    FormSubmitFn,
    IModal,
    AssignPlotterForm,
    DropdownItem,
} from '../types'

import styles from './Modal.module.scss'
import { useTranslation } from 'react-i18next'
import InfiniteMultiDropdownWithSearchField from './forms/InfiniteMultiDropdownWithSearchField'
import { getPlotter } from 'api'

export type SelectPlotterModalProps = {
    modal: IModal
    plotters: DropdownItem[] | undefined
    onSubmit: FormSubmitFn<AssignPlotterForm>
}

export default function SelectPlotterModal({
    modal,
    plotters,
    onSubmit,
}: SelectPlotterModalProps) {
    const { t } = useTranslation()
    const cx = getCxFromStyles(styles)

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-white text-center"
                >
                    {t('assign.plotter.title')}
                </DialogTitle>
                <Formik<AssignPlotterForm>
                    onSubmit={onSubmit}
                    initialValues={{
                        attach: [],
                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form className="px-2 pb-2">
                            <div className="py-6 text-gray-600 text-sm leading-5 font-normal">
                                {t('assign.plotter.description')}
                            </div>
                            <div className="mt-6">
                                <Label>{t('assign.plotter.label')}</Label>
                                <InfiniteMultiDropdownWithSearchField
                                    name="attach"
                                    queryFn={getPlotter}
                                    mapFn={plotterMapFn}
                                    queryFilters={{ assigned: false }}
                                />
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={
                                        isSubmitting || !values.attach.length
                                    }
                                >
                                    {t('form.add')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
