import {
    useQuery,
    useMutation,
    UseQueryOptions,
    UseQueryResult,
    UseMutationOptions,
} from 'react-query'
import qs from 'qs'

import client from '../client'

import {
    QUERY_CACHE_USERS_KEY,
    QUERY_CACHE_PROFILE_KEY,
} from '../../constants/index'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    UserResponse,
    UserListResponse,
    UpdateUserVariables,
    CreateUserVariables,
    DeleteVariables,
} from '../types'

export const getCurrentUser = (): Promise<ResponseData<UserResponse>> =>
    client.get('/profile')

export const useGetUser = (
    queryOptions?:
        | UseQueryOptions<
              ResponseData<UserResponse>,
              ResponseError,
              ResponseData<UserResponse>,
              string[]
          >
        | undefined
): UseQueryResult<ResponseData<UserResponse>, ResponseError> =>
    useQuery([QUERY_CACHE_PROFILE_KEY], () => getCurrentUser(), queryOptions)

export const getUsers = <T>(filters?: {}): Promise<T> =>
    client.get('/users' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useUserQuery = <T = ResponseList<UserListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_USERS_KEY, 'index', filters],
        () => getUsers<T>(filters),
        options
    )

export const getUserById = (id: string): Promise<ResponseData<UserResponse>> =>
    client.get(`/users/${id}`)

export const useUserByIdQuery = <TData = ResponseData<UserResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<UserResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery([QUERY_CACHE_USERS_KEY, id], () => getUserById(id), queryOptions)

const updateUser = ({
    id,
    data,
}: UpdateUserVariables): Promise<ResponseData<UserResponse>> =>
    client.patch(`/users/${id}`, data)

export const useUpdateUser = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<UserResponse>,
            ResponseError,
            UpdateUserVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateUser(data), options)

const deleteUser = ({ id }: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/users/${id}`)

export const useDeleteUser = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteUser(data), options)

const createUser = ({
    data,
}: CreateUserVariables): Promise<ResponseData<UserResponse>> =>
    client.post(`/users`, data)

export const useCreateUser = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<UserResponse>,
            ResponseError,
            CreateUserVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createUser(data), options)
