import React, { useMemo } from 'react'
import classNames from 'classnames'
import { SearchBar } from './ui'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'

export interface PageLayoutProps {
    className?: string
    renderTitle?: React.ReactElement
    title?: string
    children?: React.ReactElement
    actions?: React.ReactElement
    searchBarValue?: string
    searchBarAction?: (value: string) => void
    breadCrumbs?: { name: string; url?: string }[]
}

export const PageLayout: React.FC<PageLayoutProps> = ({
    className,
    title,
    renderTitle,
    children,
    actions,
    searchBarValue,
    searchBarAction,
    breadCrumbs,
}) => {
    const BreadCrumbs = useMemo(
        () =>
            breadCrumbs
                ?.map(({ name, url }, index) =>
                    url ? (
                        <Link
                            key={index}
                            to={url}
                            className="text-gray-400 text-sm leading-5 font-medium"
                        >
                            {name}
                        </Link>
                    ) : (
                        <span
                            key={index}
                            className="text-gray-400 text-sm leading-5 font-medium"
                        >
                            {name}
                        </span>
                    )
                )
                .flatMap((e, i) => [
                    e,
                    <span key={10 + i}>
                        <ChevronRightIcon className="w-5 h-5 text-gray-400" />
                    </span>,
                ])
                .slice(0, -1),
        [breadCrumbs]
    )

    return (
        <div
            className={classNames(
                'flex flex-col flex-1 print:p-0 pb-8',
                className
            )}
        >
            <div className="print:p-0 px-8">
                {!!searchBarAction ? (
                    <SearchBar
                        value={searchBarValue || ''}
                        action={searchBarAction}
                    />
                ) : (
                    <div className="h-16"></div>
                )}
                <div className="flex items-center gap-4 mt-6">
                    {BreadCrumbs}
                </div>
                <div className="flex">
                    <div className="flex-1 py-4">
                        <h2 className="text-2xl leading-8 font-semibold">
                            {!!renderTitle && renderTitle}
                            {!renderTitle && (
                                <span className="font-bol">{title}</span>
                            )}
                        </h2>
                    </div>
                    {!!actions && (
                        <div className="flex-1 py-4">
                            <div className="flex items-center justify-end print:hidden">
                                {actions}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-col flex-1 print:p-0 px-8">
                {children}
            </div>
        </div>
    )
}

export default PageLayout
