import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/outline'

import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react'
import { Button } from './ui'

export default function UserTabView({
    children,
    onAction,
    haveUsers,
}: {
    children: React.ReactNode
    onAction?: () => void
    haveUsers: boolean
}) {
    const { t } = useTranslation()

    return (
        <TabGroup>
            <div className="flex space-between">
                {/*border-b border-gray-600*/}
                <TabList className="relative left-0 top-0 justify-center items-center gap-8 inline-flex border-b-gray-600">
                    <TabElement label={t('form.user.tabs.data')} />
                    <TabElement label={t('form.user.tabs.features')} />
                    <TabElement
                        disabled={!haveUsers}
                        label={t('form.user.tabs.users')}
                    />
                </TabList>
                {onAction && (
                    <Button className="ml-auto" onClick={onAction}>
                        <PlusIcon className="w-5 h-5 mr-2" />
                        {t('form.user.add_plotter')}
                    </Button>
                )}
            </div>
            <TabPanels>{children}</TabPanels>
        </TabGroup>
    )
}

const TabElement = ({
    label,
    disabled,
}: {
    label: string
    disabled?: boolean
}) => (
    <Tab
        disabled={disabled}
        className={classNames(
            'h-full data-[selected]:text-red-600 data-[selected]:border-b-2 border-red-600 text-sm leading-5 font-medium p-1',
            {
                'text-gray-500': disabled,
                'text-white': !disabled,
            }
        )}
    >
        {label}
    </Tab>
)
