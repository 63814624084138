import React, { useState, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DEFAULT_LIST_PER_PAGE,
    QUERY_CACHE_USERS_KEY,
} from '../constants/index'
import { useUpdateUser, useUserQuery } from '../api'
import { listReducer } from '../helpers'
import { useFlashMessage, useModal } from '../hooks'

import { Button, FilterButton } from '../components/ui'
import UserList from '../components/UserList'
import UserListFilters from '../components/UserListFilters'
import SelectUserModal from './SelectUserModal'

import type {
    UserListFilterState,
    UserListFilterAction,
    ListFilterAction,
    FormSubmitFn,
    AssignUserForm,
} from '../types'
import { useQueryClient } from 'react-query'
import { useUserActiveForm } from '../hooks/forms/useUserActiveForm'
import ConfirmModal from './ConfirmModal'

const filterReducer = (
    state: UserListFilterState,
    action: UserListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterStatus') {
        return {
            ...state,
            status: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterRole') {
        return {
            ...state,
            role: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            role: undefined,
            status: undefined,
            page: 1,
        }
    }

    return listReducer(state, action as ListFilterAction) as UserListFilterState
}

export default function SupervisorUsers({ id }: { id: string }) {
    const { t } = useTranslation()

    const { form: activeUserForm, modal: activeUserFormModal } =
        useUserActiveForm()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        length: DEFAULT_LIST_PER_PAGE,
        superior_id: id,
        page: 1,
    })

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const filterCount = useMemo(
        () => (filters.role ? 1 : 0) + (filters.status ? 1 : 0),
        [filters]
    )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            status: undefined,
            role: undefined,
            roles: filters.role ? [filters.role.id] : undefined,
            active: filters.status
                ? filters.status.id === 'active'
                    ? 1
                    : 0
                : undefined,
            search_global: filters.search_global || undefined,
            superior_id: filters.superior_id,
        }
    }, [filters])

    const usersQuery = useUserQuery(apiFilters)
    const modalSelectUser = useModal()
    const flashMessage = useFlashMessage()
    const queryClient = useQueryClient()

    const updateUserQuery = useUpdateUser({
        onSuccess: async () => {},
    })

    const handleSubmit: FormSubmitFn<AssignUserForm> = (
        values,
        formikHelpers
    ) => {
        updateUserQuery.mutate(
            {
                id: String(values.assignedUser?.id!),
                data: {
                    superior: id,
                },
            },
            {
                onSuccess: async () => {
                    flashMessage({
                        type: 'success',
                        content: t('info.user.updated'),
                    })
                    formikHelpers.setSubmitting(false)
                    modalSelectUser.closeModal()
                    await queryClient.invalidateQueries([
                        QUERY_CACHE_USERS_KEY,
                        'index',
                    ])
                    queryClient.removeQueries([QUERY_CACHE_USERS_KEY])
                },
                onError: (error) => {
                    formikHelpers.setErrors(error.errors)
                    formikHelpers.setSubmitting(false)
                },
            }
        )
    }

    return (
        <div>
            <div className="flex items-center justify-end print:hidden pb-4 pt-12">
                <span className="ml-2">
                    <Button onClick={modalSelectUser.openModal}>
                        {t('action.user.create')}
                    </Button>
                </span>
                <span className="ml-6">
                    <FilterButton
                        count={filterCount}
                        filtersExpanded={filtersExpanded}
                        onClick={() =>
                            setFiltersExpanded((prevState) => !prevState)
                        }
                        handleReset={() =>
                            dispatchFilterAction({
                                type: 'resetFilters',
                            })
                        }
                    />
                </span>
            </div>
            <div>
                {filtersExpanded && (
                    <UserListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )}
                <UserList
                    usersQuery={usersQuery}
                    filters={filters}
                    filterCount={filterCount + filters.search_global.length}
                    filterAction={dispatchFilterAction}
                    handleToggle={(id, state) => {
                        activeUserFormModal.setState(id)
                        if (state === false) {
                            activeUserForm.setValues({ active: false })
                            activeUserFormModal.openModal()
                        } else {
                            activeUserForm.setValues({ active: true })
                            activeUserForm.submitForm()
                        }
                    }}
                    handleRemove={() => {}}
                />
                <SelectUserModal
                    modal={modalSelectUser}
                    onSubmit={handleSubmit}
                    title={t('attach.user.title')}
                    description={t('attach.user.description')}
                />
                <ConfirmModal
                    modal={activeUserFormModal}
                    title={t('deactivate.user.title')}
                    description={t('deactivate.user.description')}
                    actionLabel={t('deactivate.user.action')}
                    onSubmit={activeUserForm.submitForm}
                    isSubmitting={activeUserForm.isSubmitting}
                />
            </div>
        </div>
    )
}
