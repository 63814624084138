import React from 'react'
import { useField } from 'formik'

import ErrorMessage from './ErrorMessage'
import DatePicker, {
    DatePickerProps,
} from 'components/ui/DatePickers/DatePicker'

export default function DatePickerField({
    name,
    trailingAddOn,
    showErrorMessage = true,
    ...props
}: {
    name: string
    trailingAddOn?: string
    showErrorMessage?: boolean
} & DatePickerProps) {
    const [field, meta] = useField(name)

    return (
        <>
            <div className="relative">
                <DatePicker
                    hasError={showErrorMessage && !!meta.error}
                    {...props}
                />
            </div>
            {showErrorMessage && <ErrorMessage name={field.name} />}
        </>
    )
}
