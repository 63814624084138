import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Label } from './ui'
import { TextField } from './forms'

import type { ResetPasswordForm, FormSubmitFn } from '../types'

interface ResetPasswordProps {
    isSuccess: boolean
    token: string
    email: string
    handleSubmit: FormSubmitFn<ResetPasswordForm>
}

export default function ResetPassword({
    token,
    email,
    isSuccess,
    handleSubmit,
}: ResetPasswordProps) {
    const { t } = useTranslation()

    if (isSuccess) {
        return (
            <div className="space-y-6 flex flex-col text-center items-center">
                <h1 className="mt-7 text-lg leading-6 font-medium">
                    Hasło zostało ustawione
                </h1>
                <p className="mt-4 text-sm leading-5 font-normal text-gray-500">
                    Teraz możesz się zalogować, klikając w poniższy link.
                </p>
                <p className="mt-4">
                    <Button to="/login" as="link">
                        Zaloguj się
                    </Button>
                </p>
            </div>
        )
    }

    return (
        <Formik
            initialValues={{
                email: email || '',
                password: '',
                password_confirmation: '',
                token: token || '',
            }}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors }) => (
                <Form className="space-y-6">
                    <h1 className="text-lg leading-6 font-medium">
                        {t('auth.reset.title')}
                    </h1>

                    {(errors.token || errors.email) && (
                        <div>
                            {!!errors.token && (
                                <p className="mt-1 text-sm text-red-600">
                                    {errors.token}
                                </p>
                            )}
                            {!!errors.email && (
                                <p className="mt-1 text-sm text-red-600">
                                    {errors.email}
                                </p>
                            )}
                        </div>
                    )}

                    <div>
                        <Label>{t('auth.password')}</Label>
                        <TextField type="password" name="password" />
                    </div>

                    <div>
                        <Label>{t('auth.repeat_password')}</Label>
                        <TextField
                            type="password"
                            name="password_confirmation"
                        />
                    </div>

                    <div>
                        <Button
                            variant="primary"
                            type="submit"
                            className="w-full"
                            loading={isSubmitting}
                        >
                            Zapisz
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
