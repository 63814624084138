import React from 'react'

import { getCxFromStyles } from '../../helpers'

import styles from './Spinner.module.scss'

export interface Props {
    size?: 'xs' | 'sm' | 'md'
    lighter?: boolean
    darker?: boolean
    variant?:
        | 'default'
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'quaternary'
        | 'transparent'
        | 'danger'
        | 'link'
    className?: string
}
const Spinner: React.FC<Props> = ({
    size = 'md',
    lighter,
    darker,
    variant = 'default',
    className,
}) => {
    const cx = getCxFromStyles(styles)

    const getSize = () => {
        const s = size === 'md' ? 36 : size === 'sm' ? 24 : 16

        return {
            width: s,
            height: s,
        }
    }

    return (
        <div
            className={cx(
                'root',
                variant,
                {
                    xsmall: size === 'xs',
                    small: size === 'sm',
                    lighter: lighter,
                    darker: darker,
                },
                className
            )}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin"
                {...getSize()}
                viewBox="0 0 48 48"
            >
                <g fill="none">
                    <path
                        id="track"
                        fillOpacity="0.3"
                        d="M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z"
                    />
                    <path
                        id="section"
                        d="M24,0 C37.254834,0 48,10.745166 48,24 L44,24 C44,12.954305 35.045695,4 24,4 L24,0 Z"
                    />
                </g>
            </svg>
        </div>
    )
}

export default Spinner