import React from 'react'
import {
    ComboboxEmptyDropdownItem,
    ComboboxLoaderDropdownItem,
} from 'components/ui'

type OptionsProps = {
    isEmpty: boolean
    isLoading?: boolean
    hasNextPage: boolean
    children: JSX.Element | JSX.Element[]
}

const InfiniteDropdownOptions = React.forwardRef<HTMLElement, OptionsProps>(
    ({ isEmpty, isLoading, hasNextPage, children }, observerRef) => {
        if (isLoading) {
            return <ComboboxLoaderDropdownItem />
        }

        if (!isEmpty) {
            return (
                <>
                    {children}
                    {hasNextPage && (
                        <ComboboxLoaderDropdownItem
                            key={-1}
                            ref={observerRef}
                        />
                    )}
                </>
            )
        }

        return <ComboboxEmptyDropdownItem />
    }
)

export default InfiniteDropdownOptions
