import { useTranslation } from 'react-i18next'

export const useDeviceStatuses = () => {
    const { t } = useTranslation()

    return [
        { id: 'has', name: t('dictionary.has') },
        { id: 'hasnot', name: t('dictionary.hasnot') },
    ]
}
