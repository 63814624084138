import React, { useCallback, useState } from 'react'
import { InputText } from '.'
import debounce from 'lodash.debounce'

export default function SearchField({
    action,
}: {
    value?: string
    action: (value: string) => void
}) {
    const [inputValue, setInputValue] = useState('')

    const debouncedAction = useCallback(debounce(action, 200), [action])
    const handleChange = (value: string) => {
        setInputValue(value)
        if (!value.length) {
            action(value)
        } else {
            debouncedAction(value)
        }
    }

    return (
        <>
            <InputText
                value={inputValue}
                onChange={(event) => handleChange(event.target.value)}
            />
        </>
    )
}
