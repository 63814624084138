import React from 'react'
import classNames from 'classnames'

type TableProps = {
    children: React.ReactNode
}

export const TableHeader: React.FC<TableProps> = ({ children }) => (
    <thead className="text-gray-200 border-b border-b-gray-700 uppercase text-xs leading-4 font-medium">
        <tr>{children}</tr>
    </thead>
)

export const TableContainer: React.FC<TableProps> = ({ children }) => (
    <div>{children}</div>
)

export const Table: React.FC<TableProps> = ({ children }) => (
    <div className="border-b border-gray-700 mb-5">
        <table className="border-b border-gray-800 min-w-full divide-y divide-gray-700  bg-gray-800 rounded-t-lg">
            {children}
        </table>
    </div>
)

export const Tbody: React.FC<TableProps> = ({ children }) => (
    <tbody className="text-white text-sm divide-y divide-gray-700 leading-5">
        {children}
    </tbody>
)

export const Tempty: React.FC<TableProps> = ({ children }) => (
    <span className="absolute whitespace-nowrap top-0 bottom-0 m-auto h-5">
        <span className="block text-sm leading-5 font-normal italic">
            {children}
        </span>
    </span>
)

type TrProps = {
    children: React.ReactNode
    index: number
}

export const Tr: React.FC<TrProps> = ({ children, index }) => (
    <tr
        className={classNames('border-x border-gray-800', {
            'bg-gray-800': index % 2,
            'bg-background': !(index % 2),
        })}
    >
        {children}
    </tr>
)
