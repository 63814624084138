import { useQuery, useMutation } from 'react-query'
import qs from 'qs'
import type { UseMutationOptions } from 'react-query'

import client from '../client'
import { QUERY_CACHE_DEVICES_KEY } from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type {
    ResponseList,
    ResponseError,
    DeleteVariables,
    DeviceListResponse,
    DeviceResponse,
    ResponseData,
    CreateDeviceVariables,
    UpdateDeviceVariables,
} from '../types'

export const getDevices = <T>(filters?: {}): Promise<T> =>
    client.get('/devices' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useDeviceQuery = <T = ResponseList<DeviceListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_DEVICES_KEY, 'index', filters],
        () => getDevices<T>({ ...filters }),
        options
    )

export const getDeviceById = (
    id: string
): Promise<ResponseData<DeviceResponse>> => client.get(`/devices/${id}`)

export const useDeviceByIdQuery = <TData = ResponseData<DeviceResponse>>(
    id: string,
    queryOptions?: UseQueryOptions<
        ResponseData<DeviceResponse>,
        ResponseError,
        TData,
        [string, string]
    >
): UseQueryResult<TData, ResponseError> =>
    useQuery(
        [QUERY_CACHE_DEVICES_KEY, id],
        () => getDeviceById(id),
        queryOptions
    )

const createDevice = ({
    data,
}: CreateDeviceVariables): Promise<ResponseData<DeviceResponse>> =>
    client.post('/devices', data)

export const useCreateDevice = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<DeviceResponse>,
            ResponseError,
            CreateDeviceVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => createDevice(data), options)

const updateDevice = ({
    id,
    data,
}: UpdateDeviceVariables): Promise<ResponseData<DeviceResponse>> =>
    client.patch(`/devices/${id}`, data)

export const useUpdateDevice = (
    options?: Omit<
        UseMutationOptions<
            ResponseData<DeviceResponse>,
            ResponseError,
            UpdateDeviceVariables
        >,
        'mutationFn'
    >
) => useMutation((data) => updateDevice(data), options)

const deleteDevice = ({ id }: DeleteVariables): Promise<ResponseData<void>> =>
    client.delete(`/devices/${id}`)

export const useDeleteDevice = (
    options?: Omit<
        UseMutationOptions<ResponseData<void>, ResponseError, DeleteVariables>,
        'mutationFn'
    >
) => useMutation((data) => deleteDevice(data), options)
