import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import {
    ScissorsIcon,
    DocumentTextIcon,
    PresentationChartBarIcon,
    CollectionIcon,
    InboxIcon,
} from '@heroicons/react/outline'
import { usePolicy } from './usePolicy'

export type Item = {
    name: string
    to: string
    icon?: React.FC<any>
    exact?: boolean
    disabled?: true
}

export type SubItem = {
    name: string
    to: string
    matches?: string[]
    exclude?: string[]
    exact?: boolean
}

export const useSidebarItems = (): Item[] => {
    const { t } = useTranslation()
    const policy = usePolicy()

    return useMemo(
        () =>
            [
                {
                    name: t('sidebar.stats'),
                    to: '/stats',
                    exact: false,
                    icon: PresentationChartBarIcon,
                },
                policy.isAdmin() && {
                    name: t('sidebar.clients'),
                    to: '/role/user',
                    exact: false,
                },
                policy.isAdmin() && {
                    name: t('sidebar.supervisors'),
                    to: '/role/superior',
                    exact: false,
                },
                {
                    name: t('sidebar.report'),
                    to: '/report',
                    exact: false,
                },
                policy.isAdmin() && {
                    name: t('sidebar.brand'),
                    to: '/brand',
                    exact: false,
                    icon: DocumentTextIcon,
                },
                policy.isAdmin() && {
                    name: t('sidebar.device'),
                    to: '/device',
                    exact: false,
                    icon: CollectionIcon,
                },
                policy.isAdmin() && {
                    name: t('sidebar.patterns'),
                    to: '/patterns',
                    exact: false,
                },
                {
                    name: t('sidebar.plotters'),
                    to: '/plotter',
                    exact: false,
                    icon: ScissorsIcon,
                },
                policy.isAdmin() && {
                    name: t('sidebar.qr'),
                    to: '/qr-generator',
                    exact: false,
                    icon: InboxIcon,
                },
                (policy.isAdmin() || policy.isSupervisor()) && {
                    name: t('sidebar.users'),
                    to: '/user',
                    exact: false,
                },
            ].filter((item) => !!item) as Item[],
        [t, policy]
    )
}
