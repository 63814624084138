import React from 'react'

import { getCxFromStyles } from '../../helpers'

import styles from './InputText.module.scss'

export type InputTextProps = {
    handleChange?: React.ChangeEventHandler<HTMLInputElement>
    variant?: 'xs' | 'sm' | 'md' | 'lg'
    textAlign?: 'left' | 'center'
    hasError?: boolean
    trailingAddOn?: string
    inputRef?: React.RefObject<HTMLInputElement>
} & React.InputHTMLAttributes<HTMLInputElement>

export default function InputText({
    type,
    disabled,
    autoComplete,
    hasError,
    handleChange,
    variant = 'md',
    textAlign = 'left',
    className,
    trailingAddOn,
    inputRef,
    ...rest
}: InputTextProps) {
    const cx = getCxFromStyles(styles)

    return (
        <div className="relative">
            <input
                type={type ?? 'text'}
                ref={inputRef}
                className={cx(
                    'root',
                    {
                        [styles.xs]: variant === 'xs',
                        [styles.sm]: variant === 'sm',
                        [styles.md]: variant === 'md',
                        [styles.lg]: variant === 'lg',
                        [styles.alignLeft]: textAlign === 'left',
                        [styles.alignCenter]: textAlign === 'center',
                        [styles.hasPostfix]: !!trailingAddOn,
                        [styles.error]: hasError,
                        [styles.disabled]: disabled,
                    },
                    className
                )}
                autoComplete={autoComplete || 'off'}
                disabled={disabled}
                onChange={handleChange}
                {...rest}
            />
            {!!trailingAddOn && (
                <div className={cx('trailing-addon', variant)}>
                    <span>{trailingAddOn}</span>
                </div>
            )}
        </div>
    )
}