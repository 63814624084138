import React from 'react'
import classNames from 'classnames'
import { ComboboxButton } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { ChevronUpIcon } from '@heroicons/react/outline'

interface ComboboxProps {
    disabled?: boolean
    open: boolean
    hasError?: boolean
    onClick?: () => void
}

const DropdownComboboxSearchButton = (props: ComboboxProps) => {
    const { disabled, open, hasError, onClick } = props
    return (
        <ComboboxButton
            onClick={onClick}
            className={classNames(
                'absolute inset-y-0 right-0 flex items-center w-full  rounded-r-md px-2 focus:outline-none'
            )}
            disabled={disabled}
        >
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                {!open && (
                    <ChevronDownIcon
                        className={classNames('h-5 w-5', {
                            'text-gray-100': !disabled && !hasError,
                            'text-red-500': !disabled && hasError,
                            'text-gray-300': disabled,
                        })}
                        aria-hidden="true"
                    />
                )}
                {open && (
                    <ChevronUpIcon
                        className={classNames('h-5 w-5', {
                            'text-gray-100': !disabled && !hasError,
                            'text-red-500': !disabled && hasError,
                            'text-gray-300': disabled,
                        })}
                        aria-hidden="true"
                    />
                )}
            </span>
        </ComboboxButton>
    )
}

export default DropdownComboboxSearchButton
