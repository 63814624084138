import React from 'react'
import { Field, FieldProps } from 'formik'
import { RadioGroup } from '@headlessui/react'

import { RadioGroupLabel, RadioGroupOption } from '../ui'

type RadioGroupFieldProps<T> = {
    name: string
    options: { name: string; description: string; value: T }[]
    Grid: React.FC<{ children: React.ReactNode }>
    handleChange?: (value: T) => void
    disabled?: boolean
}

export const RadioGroupDefaultGrid: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => <div className="grid gap-3 grid-cols-1 -space-y-px">{children}</div>

export const RadioGroupField = <T extends unknown>({
    name,
    options,
    Grid,
    handleChange,
    disabled = false,
}: React.PropsWithChildren<RadioGroupFieldProps<T>>) => {
    return (
        <Field
            name={name}
            children={({ form, field }: FieldProps) => (
                <RadioGroup
                    value={field.value}
                    onChange={(value) => {
                        form.setFieldValue(name, value)
                        handleChange && handleChange(value)
                    }}
                >
                    <Grid>
                        {options.map((item, index) => (
                            <RadioGroupOption
                                key={index}
                                value={item.value}
                                disabled={disabled}
                            >
                                {(optionRenderProps) => (
                                    <RadioGroupLabel {...optionRenderProps}>
                                        <div>{item.name}</div>
                                        <div>{item.description}</div>
                                    </RadioGroupLabel>
                                )}
                            </RadioGroupOption>
                        ))}
                    </Grid>
                </RadioGroup>
            )}
        />
    )
}
