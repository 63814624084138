import { useQuery } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_PLACES_KEY } from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type { ResponseList, ResponseError, PlaceListResponse } from '../types'

export const getPlaces = <T>(filters?: {}): Promise<T> =>
    client.get('/places' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useCitiesQuery = <T = ResponseList<PlaceListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PLACES_KEY, 'city', filters],
        () => getPlaces<T>({ ...filters, type: 'city' }),
        options
    )

export const useCountriesQuery = <T = ResponseList<PlaceListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PLACES_KEY, 'country', filters],
        () => getPlaces<T>({ ...filters, type: 'country' }),
        options
    )
