import { DropdownItem } from 'types'

export function useDropdown(
    setValue: (v: DropdownItem | null) => void,
    setQuery?: (v: string) => void
) {
    return {
        onChange: (v: DropdownItem | null) => {
            if (!v) {
                return setValue(null)
            }

            setQuery && setQuery('')
            setValue(v)
        },
    }
}
