import React from 'react'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Label } from './ui'
import { TextField } from './forms'

import type { FormSubmitFn, LoginForm } from '../types'

import { ReactComponent as ForgotIcon } from '../assets/Lock closed.svg'

export default function Login({
    onSubmit,
}: {
    onSubmit: FormSubmitFn<LoginForm>
}) {
    const { t } = useTranslation()

    return (
        <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
                <Form className="flex flex-col items-center gap-12">
                    <p className="text-sm leading-5 font-medium text-gray-400">
                        {t('auth.title')}
                    </p>
                    <div className="flex flex-col gap-4 w-[576px]">
                        <div>
                            <Label>{t('auth.email')}</Label>
                            <TextField
                                id="email"
                                name="email"
                                autoComplete="email"
                            />
                        </div>

                        <div>
                            <Label>{t('auth.password')}</Label>
                            <TextField
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                            />
                        </div>

                        <div className="flex items-center">
                            <div className="text-sm">
                                <Link
                                    to="/forgot-password"
                                    className="flex items-center gap-3"
                                >
                                    <ForgotIcon /> {t('auth.remind')}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="w-[576px]">
                        <Button
                            variant="primary"
                            className="w-full"
                            type="submit"
                            size="xl"
                            loading={isSubmitting}
                        >
                            {t('auth.login')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
