import React from 'react'
import classNames from 'classnames'
import { ComboboxInput } from '@headlessui/react'

interface ComboboxProps {
    displayValue: string
    disabled?: boolean
    hasError?: boolean
    open: boolean
    onChange: (value: string) => void
    onBlur?: () => void
    onFocus?: () => void
}

const DropdownComboboxInput = (props: ComboboxProps) => {
    const {
        displayValue,
        onChange,
        onBlur,
        onFocus,
        disabled,
        hasError,
        open,
    } = props
    return (
        <ComboboxInput
            className={classNames(
                'relative w-full border-[1px] leading-5 border-gray-800 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1',
                {
                    'focus:ring-red-600 focus:border-red-600':
                        !disabled && !hasError,
                    'border-red-300 text-red-700 ring-1 ring-offset-0 ring-red-300':
                        !disabled && hasError,
                    'bg-gray-100 pointer-events-none': disabled,
                    'cursor-pointer': !disabled,
                    'ring-1 ring-offset-0 ring-red-600 border-red-600':
                        !disabled && hasError && open,
                    'bg-gray-400': disabled,
                    'bg-gray-500': !disabled,
                }
            )}
            onChange={(event) => onChange(event.target.value)}
            onBlur={onBlur}
            onFocus={onFocus}
            displayValue={() => (open ? '' : displayValue)}
            disabled={disabled}
        />
    )
}

export default DropdownComboboxInput
