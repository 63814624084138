import React from 'react'
import { Form, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, Label } from './ui'
import { TextField } from './forms'

import type { DropdownItem, UserForm } from '../types'

import { useUserRoles } from '../hooks/useUserRoles'
import PlotterList from './PlotterList'
import { RadioGroupField, RadioGroupDefaultGrid } from './forms/RadioGroupField'
import { useSupervisorsDropdown } from '../hooks'
import { PlotterListResponse } from '../api/types'
import { usePolicy } from '../hooks/usePolicy'
import ConfirmModal from './ConfirmModal'
import DropdownWithSearchField from './forms/DropdownWithSearchField'

type UserProps = {
    values: UserForm
    plottersList: PlotterListResponse
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<UserForm>>
    isSubmitting: boolean
    readonly?: boolean
    isProfile?: boolean
    languages: DropdownItem[]
    supervisors: DropdownItem[]
    activePlotterForm?: any
    changeUserPlotterForm?: any
}

export default function User({
    values,
    plottersList,
    setFieldValue,
    isSubmitting,
    readonly = false,
    languages,
    isProfile = false,
    activePlotterForm,
    changeUserPlotterForm,
}: UserProps) {
    const userRoles = useUserRoles()
    const policy = usePolicy()
    const { t } = useTranslation()

    const supervisors = useSupervisorsDropdown()

    return (
        <Form>
            <div className="mt-8">
                <div>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Label>{t('form.user.field.first_name')}</Label>
                            <TextField name="first_name" disabled={readonly} />
                        </div>
                        <div className="col-span-1">
                            <Label>{t('form.user.field.last_name')}</Label>
                            <TextField name="last_name" disabled={readonly} />
                        </div>
                    </div>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Label>
                                {t('form.user.field.email')}
                                <span className="text-red-600">*</span>
                            </Label>
                            <TextField name="email" disabled={readonly} />
                        </div>
                        <div className="col-span-1">
                            <Label>{t('form.user.field.phone')}</Label>
                            <TextField name="phone" disabled={readonly} />
                        </div>
                    </div>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Label>{t('form.user.field.name')}</Label>
                            <TextField name="name" disabled={readonly} />
                        </div>
                        <div className="col-span-1">
                            <Label>{t('form.user.field.supervisor')}</Label>
                            <DropdownWithSearchField
                                name="supervisor"
                                items={supervisors}
                                disabled={
                                    readonly ||
                                    !policy.isAdmin() ||
                                    String(values.role) !== userRoles.user.id
                                }
                            />
                        </div>
                    </div>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Label>{t('form.user.field.language')}</Label>
                            <Dropdown
                                value={values.lang}
                                items={languages}
                                disabled={readonly}
                                onChange={(value) =>
                                    setFieldValue('lang', value)
                                }
                            />
                        </div>
                    </div>
                </div>

                {policy.isAdmin() && (
                    <>
                        <div className="border-t border-gray-600 my-5"></div>
                        <div>
                            <span className="inline-block text-lg leading-6 font-medium">
                                {t('form.user.field.role')}
                            </span>
                            <RadioGroupField<string>
                                name="role"
                                Grid={RadioGroupDefaultGrid}
                                disabled={readonly || isProfile}
                                options={userRoles.list
                                    .map((val) => ({
                                        value: val.id,
                                        name: val.name,
                                        description: val.description,
                                    }))
                                    .reverse()}
                            />
                        </div>
                    </>
                )}

                {values.role === userRoles.user.id && (
                    <>
                        <div className="border-t border-gray-600 my-5"></div>
                        <div>
                            <span className="inline-block text-lg leading-6 font-medium pb-5">
                                {t('form.user.title.plotters')}
                            </span>
                            <PlotterList
                                filters={{ search_global: '', length: 1 }}
                                filterCount={0}
                                handleRemove={() => {}}
                                handleDetach={
                                    changeUserPlotterForm
                                        ? (id) => {
                                              changeUserPlotterForm.modal.setState(
                                                  id
                                              )
                                              changeUserPlotterForm.form.setValues(
                                                  {
                                                      user: null,
                                                  }
                                              )
                                              changeUserPlotterForm.modal.openModal()
                                          }
                                        : undefined
                                }
                                handleToggle={
                                    activePlotterForm
                                        ? (id, state) => {
                                              activePlotterForm.modal.setState(
                                                  id
                                              )
                                              if (state === false) {
                                                  activePlotterForm.form.setValues(
                                                      {
                                                          active: false,
                                                      }
                                                  )
                                                  activePlotterForm.modal.openModal()
                                              } else {
                                                  activePlotterForm.form.setValues(
                                                      {
                                                          active: true,
                                                      }
                                                  )
                                                  activePlotterForm.form.submitForm()
                                              }
                                          }
                                        : undefined
                                }
                                plottersQuery={
                                    {
                                        data: {
                                            data: plottersList,
                                            meta: {
                                                current_page: 1,
                                                per_page: plottersList.length,
                                                from: 1,
                                                to: 1,
                                                last_page: 1,
                                                total: plottersList.length,
                                            },
                                        },
                                        isSuccess: true,
                                        isError: false,
                                        isFetched: true,
                                    } as any
                                }
                            />
                        </div>
                    </>
                )}
                {!readonly && (
                    <div className="flex mt-8 pt-5 items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <Button variant="secondary" as="link" to="/user">
                                {t('form.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.save')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {activePlotterForm && (
                <ConfirmModal
                    modal={activePlotterForm.modal}
                    title={t('deactivate.plotter.title')}
                    description={t('deactivate.plotter.description')}
                    actionLabel={t('deactivate.plotter.action')}
                    onSubmit={activePlotterForm.form.submitForm}
                    isSubmitting={activePlotterForm.form.isSubmitting}
                />
            )}
            {changeUserPlotterForm && (
                <ConfirmModal
                    modal={changeUserPlotterForm.modal}
                    title={t('detach.plotter.title')}
                    description={t('detach.plotter.description')}
                    actionLabel={t('detach.plotter.action')}
                    onSubmit={changeUserPlotterForm.form.submitForm}
                    isSubmitting={changeUserPlotterForm.form.isSubmitting}
                />
            )}
        </Form>
    )
}
