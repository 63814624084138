import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Spinner } from './ui'
import TableFooter from './TableFooter'
import {
    TableHeader,
    TableContainer,
    Table,
    Tbody,
    Tempty,
    Tr,
} from './ui/List'

import type { UseQueryResult } from 'react-query'
import type {
    StatisticsDetailsListFilterState,
    StatisticsDetailsListFilterAction,
} from '../types'
import type { ResponseList, StatisticsDetailsListResponse } from '../api/types'
import moment from 'moment'

type StatisticsDetailsListProps = {
    statisticsDetailsQuery: UseQueryResult<
        ResponseList<StatisticsDetailsListResponse>
    >
    filters: StatisticsDetailsListFilterState
    filterCount: number
    filterAction: React.Dispatch<StatisticsDetailsListFilterAction>
}

type TableProps = {
    data: StatisticsDetailsListResponse
}

const StatisticsDetailsList: React.FC<StatisticsDetailsListProps> = ({
    statisticsDetailsQuery,
    filters,
    filterCount,
    filterAction,
}) => {
    const { t } = useTranslation()

    return (
        <TableContainer>
            <Table>
                <TableHeader>
                    <th
                        scope="col"
                        className="w-1 min-w-[142px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.data')}
                    </th>
                    <th
                        scope="col"
                        className="w-auto px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.client')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[206px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.superior')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[186px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.cutter')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[144px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.country')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[160px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.region')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[155px] px-6 py-4 text-left tracking-wider "
                    >
                        {t('list.report.city')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[185px] px-6 py-4 text-left tracking-wider "
                    >
                        {t('list.report.device')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 min-w-[180px] px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.foil_type')}
                    </th>
                    <th
                        scope="col"
                        className="w-1 px-6 py-4 text-left tracking-wider"
                    >
                        {t('list.report.pattern')}
                    </th>
                </TableHeader>
                {statisticsDetailsQuery.isSuccess &&
                    statisticsDetailsQuery.isFetched && (
                        <>
                            {statisticsDetailsQuery.data.meta.total > 0 && (
                                <TableBody
                                    data={statisticsDetailsQuery.data.data}
                                />
                            )}
                        </>
                    )}
                {statisticsDetailsQuery.data?.meta.total === 0 && (
                    <Tbody>
                        <Tr key={-1} index={0}>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4 relative h-12">
                                {filterCount === 0 && (
                                    <Tempty>
                                        {t('list.report.empty_list')}
                                    </Tempty>
                                )}
                                {filterCount > 0 && (
                                    <Tempty>
                                        {t('list.report.empty_search')}
                                    </Tempty>
                                )}
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                            <td className="px-6 py-4">
                                <span></span>
                            </td>
                        </Tr>
                    </Tbody>
                )}
            </Table>
            {statisticsDetailsQuery.isLoading && (
                <div className="divide-y divide-gray-700 text-sm leading-5">
                    {Array.from(Array(filters.length).keys()).map(
                        (_, index) => (
                            <div
                                key={index}
                                className={classNames('relative h-[68px]', {
                                    'bg-gray-800': index % 2,
                                    'bg-background': !(index % 2),
                                })}
                            >
                                {index === 4 && (
                                    <div className="absolute inset-0 h-full flex justify-center items-center">
                                        <Spinner className="p-0" />
                                    </div>
                                )}
                                <span>&nbsp;</span>
                            </div>
                        )
                    )}
                </div>
            )}
            <TableFooter
                meta={statisticsDetailsQuery.data?.meta}
                filterAction={filterAction}
            />
        </TableContainer>
    )
}

const TableBody: React.FC<TableProps> = ({ data }) => {
    const { t } = useTranslation()

    return (
        <Tbody>
            {data.map((item, index) => (
                <Tr key={item.id} index={index}>
                    <td className="px-6 py-4">
                        <span>
                            {moment(new Date(item.created_at)).format(
                                'DD-MM-YYYY'
                            )}
                        </span>
                    </td>
                    <td className="px-6 py-4">
                        {item.client ? item.client : '-'}
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.superior ? item.superior : '-'}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.cutter_name ? item.cutter_name : '-'}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span>
                            {item.country ? t(`country.${item.country}`) : '-'}
                        </span>
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.region ? item.region : '-'}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.city ? item.city : '-'}</span>
                    </td>
                    <td className="px-6 py-4">
                        <span>
                            <div>{item.device}</div>
                            <div className="text-gray-400">{item.brand}</div>
                        </span>
                    </td>
                    <td className="px-6 py-4">
                        <span>
                            <div>
                                {t(`protection_films_types.${item.type}`)}
                            </div>
                            <div className="text-gray-400">
                                {t(`protection_films_sizes.${item.size}`)}
                            </div>
                        </span>
                    </td>
                    <td className="px-6 py-4">
                        <span>{item.pattern}</span>
                    </td>
                </Tr>
            ))}
        </Tbody>
    )
}

export default StatisticsDetailsList
