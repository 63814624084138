import { useQuery } from 'react-query'
import qs from 'qs'

import client from '../client'
import {
    QUERY_CACHE_STATISTICS_KEY,
    QUERY_CACHE_STATISTICS_COMPARE_KEY,
    QUERY_CACHE_REPORTS_KEY,
} from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type {
    ResponseData,
    ResponseList,
    ResponseError,
    StatisticsDetailsListResponse,
    StatisticsResponse,
    StatisticsCompareResponse,
} from '../types'

export const getStatistics = <T>(filters?: {}): Promise<T> =>
    client.get('/statistics' + (filters ? '?' + qs.stringify(filters, {}) : ''))

export const useStatisticsQuery = <T = ResponseData<StatisticsResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_STATISTICS_KEY, filters],
        () => getStatistics<T>({ ...filters }),
        options
    )

export const getStatisticsCompare = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/statistics/compare' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useStatisticsCompareQuery = <
    T = ResponseData<StatisticsCompareResponse>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_STATISTICS_COMPARE_KEY, filters],
        () => getStatisticsCompare<T>({ ...filters }),
        options
    )

export const getStatisticsDetails = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/statistics/details' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useStatisticsDetailsQuery = <
    T = ResponseList<StatisticsDetailsListResponse>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_REPORTS_KEY, filters],
        () => getStatisticsDetails<T>({ ...filters }),
        options
    )

export const downloadStatisticsXls = (filters?: {}): Promise<
    ResponseData<Blob>
> =>
    client.get(
        '/statistics/details' +
            (filters
                ? '?' + qs.stringify({ export: true, ...filters }, {})
                : qs.stringify({ export: true }, {})),
        {
            responseType: 'blob',
        }
    )
