import React, { useEffect, useState } from 'react'

import { Pagination } from './ui'

import type { MetaPagination } from './ui/Pagination'
import type { ListFilterAction } from '../types'

const TableFooter: React.FC<{
    meta?: MetaPagination
    filterAction: React.Dispatch<ListFilterAction>
}> = ({ meta, filterAction }) => {
    const [cachedMeta, setCachedMeta] = useState(meta)

    useEffect(() => {
        if (meta) {
            setCachedMeta(meta)
        }
    }, [meta])

    if (!cachedMeta) {
        return null
    }

    if (!cachedMeta.total) {
        return null
    }

    return (
        <Pagination
            meta={cachedMeta}
            handleChange={(page) =>
                filterAction({ type: 'setPage', payload: page })
            }
        />
    )
}

export default TableFooter
