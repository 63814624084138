import React from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Label } from './ui'
import { DropdownField, TextField } from './forms'

import type { FormSubmitFn, GenerateQRCodesForm } from '../types'

import { useFoilSizesDropdown, useFoilTypesDropdown } from '../hooks'

type QrGeneratorProps = {
    onSubmit: FormSubmitFn<GenerateQRCodesForm>
    readonly?: boolean
}

export default function QrGenerator({
    onSubmit,
    readonly = false,
}: QrGeneratorProps) {
    const { t } = useTranslation()

    const sizes = useFoilSizesDropdown()
    const types = useFoilTypesDropdown()

    return (
        <Formik<GenerateQRCodesForm>
            initialValues={{
                size: sizes[0],
                type: types[0],
                count: 0,
            }}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                    <div className="p-6 rounded-lg bg-gray-800">
                        <h2 className="text-xl leading-7 font-medium">
                            {t('form.qr.subtitle')}
                        </h2>
                        <div className="my-6 grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.size')}</Label>
                                <DropdownField
                                    name="size"
                                    value={values.size}
                                    items={sizes}
                                    disabled={readonly}
                                    onChange={(value) =>
                                        setFieldValue('size', value)
                                    }
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.type')}</Label>
                                <DropdownField
                                    name="type"
                                    value={values.type}
                                    items={types}
                                    disabled={readonly}
                                    onChange={(value) =>
                                        setFieldValue('type', value)
                                    }
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.count')}</Label>
                                <TextField name="count" disabled={readonly} />
                            </div>
                        </div>

                        <div className="">
                            <Button
                                type="submit"
                                variant="primary"
                                loading={isSubmitting}
                            >
                                {t('form.generate')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
