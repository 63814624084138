import React, { useState, useMemo, useReducer, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_LIST_PER_PAGE } from '../constants/index'
import { useUserQuery } from '../api'
import { listReducer } from '../helpers'
import { Button, FilterButton } from '../components/ui'
import PageLayout from '../components/PageLayout'
import UserList from '../components/UserList'
import UserListFilters from '../components/UserListFilters'

import type {
    UserListFilterState,
    UserListFilterAction,
    ListFilterAction,
} from '../types'
import ConfirmModal from '../components/ConfirmModal'
import { useUserActiveForm } from '../hooks/forms/useUserActiveForm'
import { useRemoveUserForm } from '../hooks/forms/useRemoveUserForm'
import { useParams } from 'react-router-dom'
import { useUserRoles } from 'hooks'
import { usePolicy } from 'hooks/usePolicy'

const filterReducer = (
    state: UserListFilterState,
    action: UserListFilterAction
) => {
    if (action.type === 'setFilterQuery') {
        return {
            ...state,
            search_global: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterStatus') {
        return {
            ...state,
            status: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterRole') {
        return {
            ...state,
            role: action.payload,
            page: 1,
        }
    }

    if (action.type === 'setFilterCountry') {
        return {
            ...state,
            country: action.payload,
            page: 1,
        }
    }

    if (action.type === 'resetFilters') {
        return {
            ...state,
            search_global: '',
            role: undefined,
            status: undefined,
            country: undefined,
            page: 1,
        }
    }

    return listReducer(state, action as ListFilterAction) as UserListFilterState
}

export default function UserListView() {
    const { t } = useTranslation()
    const { role_id } = useParams()
    const { supervisor, user } = useUserRoles()

    const { form: activeUserForm, modal: activeUserFormModal } =
        useUserActiveForm()
    const removeUserForm = useRemoveUserForm()

    const [filters, dispatchFilterAction] = useReducer(filterReducer, {
        search_global: '',
        sort_by: 'id',
        sort_direction: 'desc',
        country: undefined,
        role:
            role_id === 'superior'
                ? { name: supervisor.name, id: supervisor.id }
                : role_id === 'user'
                ? { name: user.name, id: user.id }
                : undefined,
        length: DEFAULT_LIST_PER_PAGE,
        page: 1,
    })

    useEffect(() => {
        dispatchFilterAction({
            type: 'setFilterRole',
            payload:
                role_id === 'superior'
                    ? { name: supervisor.name, id: supervisor.id }
                    : role_id === 'user'
                    ? { name: user.name, id: user.id }
                    : undefined,
        })
    }, [role_id, supervisor, user])

    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false)

    const filterCount = useMemo(
        () =>
            (filters.country ? 1 : 0) +
            (filters.role ? 1 : 0) +
            (filters.status ? 1 : 0),
        [filters]
    )

    const apiFilters = useMemo(() => {
        return {
            ...filters,
            status: undefined,
            roles: filters.role ? [filters.role.id] : undefined,
            countries: filters.country ? [filters.country.id] : undefined,
            active: filters.status
                ? filters.status.id === 'active'
                    ? 1
                    : 0
                : undefined,
            search_global: filters.search_global
                ? filters.search_global
                : undefined,
        }
    }, [filters])

    const usersQuery = useUserQuery(apiFilters)
    const policy = usePolicy()

    if (!policy.isAdmin() && !policy.isSupervisor()) {
        return <div>Forbidden</div>
    }

    return (
        <PageLayout
            title={t('user.list.title')}
            searchBarValue={filters.search_global}
            searchBarAction={(value) =>
                dispatchFilterAction({
                    type: 'setFilterQuery',
                    payload: value,
                })
            }
            actions={
                <>
                    <span className="ml-2">
                        <Button as="link" to="/user/new">
                            {t('action.user.create')}
                        </Button>
                    </span>
                    <span className="ml-6">
                        <FilterButton
                            count={filterCount}
                            filtersExpanded={filtersExpanded}
                            onClick={() =>
                                setFiltersExpanded((prevState) => !prevState)
                            }
                            handleReset={() =>
                                dispatchFilterAction({
                                    type: 'resetFilters',
                                })
                            }
                        />
                    </span>
                </>
            }
        >
            <>
                {filtersExpanded && (
                    <UserListFilters
                        filters={filters}
                        filterAction={dispatchFilterAction}
                    />
                )}
                <UserList
                    usersQuery={usersQuery}
                    filters={filters}
                    filterCount={filterCount + filters.search_global.length}
                    handleToggle={(id, state) => {
                        activeUserFormModal.setState(id)
                        if (state === false) {
                            activeUserForm.setValues({ active: false })
                            activeUserFormModal.openModal()
                        } else {
                            activeUserForm.setValues({ active: true })
                            activeUserForm.submitForm()
                        }
                    }}
                    handleRemove={(id) => {
                        removeUserForm.modal.setState(id)
                        removeUserForm.modal.openModal()
                    }}
                    filterAction={dispatchFilterAction}
                />
                <ConfirmModal
                    modal={activeUserFormModal}
                    title={t('deactivate.user.title')}
                    description={t('deactivate.user.description')}
                    actionLabel={t('deactivate.user.action')}
                    onSubmit={activeUserForm.submitForm}
                    isSubmitting={activeUserForm.isSubmitting}
                />
                <ConfirmModal
                    modal={removeUserForm.modal}
                    title={t('delete.user.title')}
                    description={t('delete.user.description')}
                    actionLabel={t('delete.user.action')}
                    onSubmit={removeUserForm.form.submitForm}
                    isSubmitting={removeUserForm.form.isSubmitting}
                />
            </>
        </PageLayout>
    )
}
