import { DropdownItem } from 'types'

export function useMultiDropdown(
    setValue: (v: DropdownItem[]) => void,
    value: DropdownItem[],
    setQuery?: (v: string) => void
) {
    return {
        onChange: (v: DropdownItem | null) => {
            if (!v) {
                return setValue([])
            }

            setQuery && setQuery('')
            setValue(
                value.find((fv: DropdownItem) => fv.id === v.id)
                    ? value.filter((fv: DropdownItem) => fv.id !== v.id)
                    : value.concat(v)
            )
        },
    }
}
