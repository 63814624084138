import React from 'react'
import { Routes, Route } from 'react-router-dom'

import AuthRoutes from './AuthRoutes'
import ScrollToTop from './components/ScrollToTop'
import MainLayout from './components/MainLayout'
import LoginLayout from './components/LoginLayout'
import Login from './pages/Login'
import Main from './pages/Main'
import ForgotPassword from './pages/ForgotPassword'
import SetPassword from './pages/SetPassword'
import ResetPassword from './pages/ResetPassword'
import Logout from './pages/Logout'
import UserListView from './pages/UserList'
import NewUserView from './pages/NewUser'
import ProfileView from './pages/Profile'
import EditUserView from './pages/User'
import PlotterListView from './pages/PlotterList'
import StatisticsFullView from './pages/StatisticsFullView'
import StatisticsDetailsListView from 'pages/StatisticsDetails'
import BrandListView from 'pages/BrandList'
import DeviceListView from 'pages/DeviceList'
import PatternListView from 'pages/PatternList'
import QrGeneratorView from 'pages/QrGenerator'
import DevicePatternListView from 'pages/DevicePatternList'

export default function MainRoutes() {
    return (
        <ScrollToTop>
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="/set-password" element={<SetPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                <Route element={<AuthRoutes />}>
                    <Route element={<MainLayout />}>
                        <Route path="/" element={<Main />} />
                        <Route path="/profile" element={<ProfileView />} />
                        <Route path="/plotter">
                            <Route index element={<PlotterListView />} />
                        </Route>
                        <Route path="/brand">
                            <Route index element={<BrandListView />} />
                            <Route
                                path=":id/devices"
                                element={<DeviceListView />}
                            />
                        </Route>
                        <Route path="/device">
                            <Route index element={<DeviceListView />} />
                            <Route
                                path=":device_id/patterns"
                                element={<DevicePatternListView />}
                            />
                        </Route>
                        <Route path="/patterns">
                            <Route index element={<PatternListView />} />
                        </Route>
                        <Route path="/user">
                            <Route index element={<UserListView />} />
                            <Route path="new" element={<NewUserView />} />
                            <Route path=":id/edit" element={<EditUserView />} />
                        </Route>
                        <Route path="/stats">
                            <Route index element={<StatisticsFullView />} />
                            <Route
                                path="supervisor/:supervisor_id"
                                element={<StatisticsFullView />}
                            />
                            <Route
                                path="user/:user_id"
                                element={<StatisticsFullView />}
                            />
                        </Route>
                        <Route path="/report">
                            <Route
                                index
                                element={<StatisticsDetailsListView />}
                            />
                            <Route
                                path="supervisor/:supervisor_id"
                                element={<StatisticsDetailsListView />}
                            />
                            <Route
                                path="user/:user_id"
                                element={<StatisticsDetailsListView />}
                            />
                        </Route>
                        <Route path="/qr-generator">
                            <Route index element={<QrGeneratorView />} />
                        </Route>
                        <Route path="/role/:role_id">
                            <Route index element={<UserListView />} />
                        </Route>
                    </Route>
                    <Route path="/logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<div>404</div>} />
            </Routes>
        </ScrollToTop>
    )
}
