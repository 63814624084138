import { useTranslation } from 'react-i18next'

export const useUserStatuses = () => {
    const { t } = useTranslation()

    return [
        { id: 'active', name: t('dictionary.active') },
        { id: 'inactive', name: t('dictionary.inactive') },
    ]
}
