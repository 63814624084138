import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'

import FlashMessageContext from './FlashMessageContext'
import FlashMessage from '../components/FlashMessage'

import type {
    FlashMessage as TFlashMessage,
    FlashMessageContext as TFlashMessageContext,
} from './FlashMessageContext'

const FlashMessageProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { Provider } = FlashMessageContext
    const [message, setMessage] = useState<
        TFlashMessageContext['message'] | null
    >(null)
    const [showMessage, setShowMessage] = useState<boolean>(false)
    const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null)

    const dismiss: TFlashMessageContext['dismiss'] = useCallback(() => {
        setShowMessage(false)
        timeoutHandler.current && clearTimeout(timeoutHandler.current)
    }, [])

    const show: TFlashMessageContext['show'] = useCallback(
        (flashMessage: TFlashMessage, timeout = 3000) => {
            if (flashMessage) {
                setMessage(flashMessage)
                setShowMessage(true)

                if (timeout > 0) {
                    timeoutHandler.current = setTimeout(dismiss, timeout)
                }
            }
        },
        [dismiss]
    )

    useEffect(() => {
        return () => {
            timeoutHandler.current && clearTimeout(timeoutHandler.current)
        }

        // eslint-disable-next-line
    }, [])

    const contextValue = useMemo(
        () => ({
            message,
            dismiss,
            show,
        }),
        [show, message, dismiss]
    )

    return (
        <Provider value={contextValue}>
            <div className="fixed top-20 z-50 left-4 right-4 md:left-1/2 md:right-auto md:transform md:-translate-x-1/2 m-auto">
                <FlashMessage
                    dismiss={dismiss}
                    show={showMessage}
                    type={message?.type}
                >
                    {message?.content}
                </FlashMessage>
            </div>
            {children}
        </Provider>
    )
}

export default FlashMessageProvider