import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { useRemindPassword } from '../../api'
import { useFlashMessage } from '..'

export function useRemindPasswordForm() {
    const { t } = useTranslation()

    const flashMessage = useFlashMessage()

    const remindPassword = useRemindPassword()

    const form = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values, formikHelpers) => {
            remindPassword.mutate(
                {
                    email: values.email,
                },
                {
                    onSuccess: async () => {
                        flashMessage({
                            type: 'success',
                            content: t('info.user.link_send'),
                        })
                        formikHelpers.setSubmitting(false)
                    },
                    onError: (error) => {
                        formikHelpers.setErrors(error.errors)
                        formikHelpers.setSubmitting(false)
                    },
                }
            )
        },
    })

    return {
        form: form,
        sendForm: async (email: string) => {
            await form.setValues({ email: email })
            await form.submitForm()
        },
    }
}
