import React from 'react'
import classNames from 'classnames'
import { ComboboxOption, ListboxOption } from '@headlessui/react'
import { CheckIcon, PlusIcon } from '@heroicons/react/solid'

import type { DropdownItem } from 'types'
import { useTranslation } from 'react-i18next'
import Loader from 'components/ui/Loader'

export function DropdownItemComponent({
    item,
    selected,
    disabled,
}: {
    item: DropdownItem
    selected?: boolean
    disabled?: boolean
}) {
    return (
        <>
            <span
                className={classNames(
                    'block truncate font-semibold text-gray-50'
                )}
            >
                {item.description ? (
                    <>
                        <div className="truncate">{item.name}</div>
                        <div className="truncate text-sm leading-5 font-normal text-gray-300">
                            {item.description}
                        </div>
                    </>
                ) : (
                    item.name
                )}
            </span>

            {!disabled && (
                <span>
                    <span
                        className={classNames(
                            'absolute inset-y-0 right-0 flex items-center p-[3px] mr-4 w-[20px] h-[20px] my-auto rounded-[17px]',
                            {
                                'bg-red-600': !selected,
                                'bg-white': selected,
                            }
                        )}
                    >
                        {selected ? (
                            <CheckIcon
                                className="h-5 w-5 text-red-600"
                                aria-hidden="true"
                            />
                        ) : (
                            <PlusIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                            />
                        )}
                    </span>
                </span>
            )}
        </>
    )
}

export const LoaderItem = React.forwardRef<HTMLElement>((props, ref) => (
    <span
        className={classNames('block truncate font-semibold text-gray-50')}
        ref={ref}
    >
        <Loader size="sm" />
    </span>
))

export function ComboboxDropdownItem({
    item,
    selected,
    disabled,
}: {
    item: DropdownItem
    selected?: boolean
    disabled?: boolean
}) {
    return (
        <ComboboxOption
            disabled={disabled}
            className={({ focus }) =>
                classNames(
                    {
                        'bg-gray-800': focus,
                        'bg-gray-600': !focus,
                    },
                    'cursor-pointer select-none relative py-4 pl-3 pr-10'
                )
            }
            value={item}
        >
            <DropdownItemComponent
                item={item}
                selected={selected}
                disabled={disabled}
            />
        </ComboboxOption>
    )
}

export function ComboboxEmptyDropdownItem() {
    const { t } = useTranslation()
    return (
        <ComboboxDropdownItem
            item={{
                id: '',
                name: t('list.empty_list'),
            }}
            disabled={true}
        />
    )
}

export const ComboboxLoaderDropdownItem = React.forwardRef<HTMLElement>(
    (props, ref) => (
        <ComboboxOption disabled={true} value={{}}>
            {() => <LoaderItem ref={ref} />}
        </ComboboxOption>
    )
)

export function ListboxDropdownItem({
    item,
    selected,
    disabled,
}: {
    item: DropdownItem
    selected: boolean
    disabled?: boolean
}) {
    return (
        <ListboxOption
            disabled={disabled}
            className={({ focus }) =>
                classNames(
                    {
                        'bg-gray-800': focus,
                        'bg-gray-600': !focus,
                    },
                    'cursor-pointer select-none relative py-4 pl-3 pr-10'
                )
            }
            value={item}
        >
            <DropdownItemComponent
                item={item}
                selected={selected}
                disabled={disabled}
            />
        </ListboxOption>
    )
}
