import { useEffect, useRef, useState } from 'react'

export function useDropdownIsOpen() {
    const [open, setIsOpen] = useState(false)
    const comboboxRef = useRef<HTMLElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                comboboxRef.current &&
                !comboboxRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return {
        open,
        setIsOpen,
        comboboxRef,
    }
}
