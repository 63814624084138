import { useMutation, useQuery } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_PROTECTION_FILMS_KEY } from '../../constants'

import type {
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query'
import type {
    ResponseError,
    GenerateQrCodesVariables,
    DownloadQrCodesVariables,
    GenerateQrCodesResponse,
    ResponseData,
    ProtectionFilmsResponse,
} from '../types'

export const getProtectionFilmsStatistics = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/protection-films' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useGetProtectionFilmsStatistics = <
    T = ResponseData<ProtectionFilmsResponse>,
>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_PROTECTION_FILMS_KEY, filters],
        () => getProtectionFilmsStatistics<T>({ ...filters }),
        options
    )

const generateQRCodes = (
    data: GenerateQrCodesVariables
): Promise<ResponseData<GenerateQrCodesResponse>> =>
    client.post('/protection-films/generate', data)

export const useGenerateQrCodes = (): UseMutationResult<
    ResponseData<GenerateQrCodesResponse>,
    ResponseError,
    GenerateQrCodesVariables
> => useMutation((data) => generateQRCodes(data))

const downloadQRCodes = (
    data: DownloadQrCodesVariables
): Promise<ResponseData<Blob>> =>
    client.post('/protection-films/download-csv', data, {
        responseType: 'blob',
    })

export const useDownloadQRCodes = (): UseMutationResult<
    ResponseData<Blob>,
    ResponseError,
    DownloadQrCodesVariables
> => useMutation((data) => downloadQRCodes(data))
